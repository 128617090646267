function getCookie(cookieName) {
  const decodedCookies = decodeURIComponent(document.cookie);
  const cookiesEntities = decodedCookies.replace(/\s+/g, '').split(';');

  const parsedCookies = cookiesEntities.reduce((obj, cookie) => {
    const cookieKey = cookie.includes('|')
      ? cookie.split('|')[0].split('=')[0]
      : cookie.split('=')[0];

    const cookieValue = cookie.includes('|')
      ? cookie.split('|')[0].split('=')[1]
      : cookie.split('=')[1];

    return { ...obj, [cookieKey]: cookieValue };
  }, {});

  return parsedCookies[cookieName];
}

export { getCookie };
