import React, { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Container } from 'components';
import { LoadingSpinner } from 'components/elements';

export const Opportunities = React.lazy(() => import('../pages/opportunities/Opportunities'));
export const Opportunity = React.lazy(() => import('../pages/opportunity/Opportunity'));
export const NotFound = React.lazy(() => import('../pages/notFound/NotFound'));

function Common() {
  return (
    <Container>
      <Suspense fallback={<LoadingSpinner />}>
        <Outlet />
      </Suspense>
    </Container>
  );
}

export const commonRoutes = [
  {
    path: '/',
    element: <Common />,
    children: [
      { index: true, element: <Navigate to="/opportunities" /> },
      { path: '/opportunities', element: <Opportunities /> },
      { path: '/opportunities/:id', element: <Opportunity /> },
      { path: '*', element: <NotFound /> },
    ],
  },
];
