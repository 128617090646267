import styled from '@emotion/styled';
import { palette, spacing, borderRadius, typography, effects, breakpoints, boxShadow } from 'theme';

export const AddressLookup = styled.div({
  position: 'relative',
  width: '100%',
});

export const InnerContainer = styled.div({
  position: 'relative',
  backgroundColor: palette.primary[100],
  padding: spacing[8],
  paddingTop: '0',
  borderRadius: borderRadius.lg,

  [`${breakpoints.tabletDown}`]: {
    maxWidth: 'none',
    width: '100%',
  },
});

export const Listbox = styled.ul({
  position: 'absolute',
  zIndex: 10,
  left: '4px',
  width: 'fit-content',
  padding: 0,
  margin: 0,
  paddingBlock: spacing[12],
  paddingInline: spacing[12],
  maxHeight: '360px',
  overflow: 'auto',
  fontFamily: typography.fonts.secondary.regular,
  fontSize: typography.fontSizes.m,
  listStyle: 'none',
  backgroundColor: palette.neutral[100],
  borderRadius: borderRadius.base,
  boxShadow: boxShadow.base,

  [`${breakpoints.tabletDown}`]: {
    width: '100%',
  },
});

export const ListItem = styled.li({
  paddingBlock: spacing[4],
});

export const ListButton = styled.button({
  border: 'none',
  textAlign: 'left',
  background: 'none',
  cursor: 'pointer',
  padding: 0,
  transition: `all, ${effects.transition}`,
  color: palette.neutral[700],

  '&:hover': {
    color: palette.primary[300],
  },
});
