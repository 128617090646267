/**
 * React-Axe tests the accessibility of the fully rendered DOM.
 * Logs accessibility issues in the browser DevTools console.
 * Only use in development environment!
 * Learn more: https://www.npmjs.com/package/@axe-core/react#advantages
 */
const reportAxeCore = (React, ReactDOM) => {
  if (process.env.NODE_ENV !== 'production') {
    import('@axe-core/react').then(({ default: axe }) => {
      axe(React, ReactDOM, 1000);
    });
  }
};

export default reportAxeCore;
