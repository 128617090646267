import { useQuery } from 'react-query';
import { axiosAuth } from 'lib';
import { useAuth } from 'context';

export const getAssignments = () => {
  return axiosAuth.get(`/assignments/me`);
};

export const useAssignments = () => {
  const { user, isUserAuthenticated } = useAuth();
  return useQuery(['assignments', user.id], () => getAssignments(), {
    enabled: isUserAuthenticated,
    refetchOnWindowFocus: true,
    retry: 2,
    staleTime: 60 * 1000,
  });
};
