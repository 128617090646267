import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

function Portal({ children }) {
  return ReactDOM.createPortal(<>{children}</>, document.getElementById('modal-root'));
}

Portal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Portal;
