import styled from '@emotion/styled';
import { palette, typography, spacing, borderRadius, boxShadow, breakpoints } from 'theme';

export const Table = styled.table({
  width: '100%',
  height: '100%',
  position: 'absolute',
  tableLayout: 'fixed',
  borderCollapse: 'collapse',
  backgroundColor: palette.neutral[100],
  borderRadius: borderRadius.lg,
  boxShadow: boxShadow.base,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
});

export const Head = styled.thead({
  [`${breakpoints.tabletDown}`]: {
    display: 'block',
    position: 'absolute',
    top: '-9999px',
    left: '-9999px',
  },
});

export const HeadRow = styled.tr({
  display: 'block',

  [`${breakpoints.tabletDown}`]: {
    /* Hide table headers (but not display: none;, for accessibility) */
    position: 'absolute',
    top: '-9999px',
    left: '-9999px',
  },
});

export const Th = styled.th({
  display: 'inline-block',
  padding: `${spacing[8]} ${spacing[12]}`,
  fontFamily: typography.fonts.primary.bold,
  fontSize: typography.fontSizes.xs,
  color: palette.neutral[600],
  textTransform: 'uppercase',
  letterSpacing: '0.5px',
  textAlign: 'left',
  fontWeight: 'normal',

  '&.distance': {
    width: '7%',
  },

  '&.name': {
    width: '25%',
  },

  '&.city': {
    width: '15%',
  },

  '&.description': {
    width: '35%',
  },
});

export const Body = styled.tbody({
  width: '100%',
  height: '100%',
  overflowY: 'auto',
  overflowX: 'hidden',
  scrollbarGutter: 'stable',
  backgroundColor: palette.neutral[100],
  overflowAnchor: 'auto',

  // Table Scrollbar styling
  scrollbarWidth: 'thin',
  scrollbarColor: palette.neutral[400],

  // Works on Chrome, Edge, and Safari
  '&::-webkit-scrollbar': {
    width: '4px',
    height: '4px',
  },

  '&::-webkit-scrollbar-thumb': {
    background: palette.neutral[300],
    borderRadius: '4px',
  },

  '&:hover': {
    '&::-webkit-scrollbar-thumb': {
      background: palette.neutral[400],
      bordeRadius: '4px',
    },
  },
});

export const BodyRow = styled.tr({
  height: 'calc(100% / 8)',
  minHeight: '88px',
  display: 'flex',
  alignItems: 'center',
  '&:nth-of-type(odd)': {
    backgroundColor: palette.neutral[150],
  },

  [`${breakpoints.tabletDown}`]: {
    flexDirection: 'column',
    alignItems: 'stretch',
    height: 'auto',
    padding: `${spacing[8]}`,

    '&:nth-of-type(even)': {
      backgroundColor: palette.neutral[150],
    },

    '&:nth-of-type(odd)': {
      backgroundColor: palette.neutral[100],
    },
  },
});

export const Td = styled.td({
  display: 'inline-block',
  padding: `0 ${spacing[12]}`,
  color: palette.neutral[700],
  fontSize: typography.fontSizes.m,
  textAlign: 'left',

  '&.distance': {
    width: '7%',
    fontFamily: typography.fonts.secondary.bold,
    color: palette.primary[700],

    [`${breakpoints.tabletDown}`]: {
      width: '100%',
      padding: 0,
      paddingBottom: spacing[8],

      '&::after': {
        content: '"  Miles"',
      },
    },
  },

  '&.name': {
    width: '25%',
    fontFamily: typography.fonts.secondary.bold,
    fontSize: typography.fontSizes.l,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    [`${breakpoints.tabletDown}`]: {
      width: '100%',
      padding: 0,
      paddingBottom: spacing[12],
    },
  },

  '&.city': {
    width: '15%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    [`${breakpoints.tabletDown}`]: {
      width: '100%',
      padding: 0,
      paddingBottom: spacing[12],
    },
  },

  '&.description': {
    width: '35%',

    [`${breakpoints.tabletDown}`]: {
      width: '100%',
      maxWidth: '90ch',
      padding: 0,
      paddingBottom: spacing[12],
    },
  },
  '&.description-button': {
    width: '17%',
    textAlign: 'right',

    [`${breakpoints.tabletDown}`]: {
      width: '100%',
      padding: '0',
      textAlign: 'left',
    },
  },
});

export const Foot = styled.tfoot({
  width: '100%',
  display: 'block',
  backgroundColor: palette.neutral[150],

  '& > tr': {
    display: 'block',
  },

  '& > tr > td': {
    display: 'block',
    padding: spacing[8],
    textAlign: 'center',
  },
});
