import React from 'react';
import PropTypes from 'prop-types';
import { cx, css } from '@emotion/css';
import * as S from './styles';

function Box({
  as,
  label,
  padding,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
  paddingX,
  paddingY,
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  marginX,
  marginY,
  width,
  display,
  className,
  cssOverrides,
  children,
  ...rest
}) {
  return (
    <S.Box
      as={as}
      label={label}
      padding={padding}
      paddingTop={paddingTop}
      paddingRight={paddingRight}
      paddingBottom={paddingBottom}
      paddingLeft={paddingLeft}
      paddingX={paddingX}
      paddingY={paddingY}
      margin={margin}
      marginTop={marginTop}
      marginRight={marginRight}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      marginX={marginX}
      marginY={marginY}
      width={width}
      display={display}
      data-testid="common-box"
      className={cx(
        {
          className,
        },
        css({
          ...cssOverrides,
        }),
      )}
      {...rest}
    >
      {children}
    </S.Box>
  );
}

Box.defaultProps = {
  as: 'div',
  label: '',
  padding: null,
  paddingTop: null,
  paddingRight: null,
  paddingBottom: null,
  paddingLeft: null,
  paddingX: null,
  paddingY: null,
  margin: null,
  marginTop: null,
  marginRight: null,
  marginBottom: null,
  marginLeft: null,
  marginX: null,
  marginY: null,
  width: 'full',
  className: '',
  cssOverrides: {},
  display: 'block',
};

Box.propTypes = {
  as: PropTypes.string,
  label: PropTypes.string,
  padding: PropTypes.number,
  paddingTop: PropTypes.number,
  paddingRight: PropTypes.number,
  paddingBottom: PropTypes.number,
  paddingLeft: PropTypes.number,
  paddingX: PropTypes.number,
  paddingY: PropTypes.number,
  margin: PropTypes.number,
  marginTop: PropTypes.number,
  marginRight: PropTypes.number,
  marginBottom: PropTypes.number,
  marginLeft: PropTypes.number,
  marginX: PropTypes.number,
  marginY: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  display: PropTypes.string,
  className: PropTypes.string,
  cssOverrides: PropTypes.shape({}),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
};

export default Box;
