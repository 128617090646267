/**
 * @param {string} key
 * @returns {string|object}
 */
export const getValueFromLocalStorage = (key) => {
  const value = window.localStorage.getItem(key);
  let sanitized;
  try {
    sanitized = JSON.parse(value);
  } catch (e) {
    sanitized = value;
  }
  return sanitized;
};

/**
 * `JSON.stringify` stored value
 * @param {string} key
 * @param {string|object} value
 */
export const setValueInLocalStorage = (key, value) => {
  if (key && value) {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
};

/**
 * @param {string} key
 */
export const removeValueFromLocalStorage = (key) => {
  window.localStorage.removeItem(key);
};

/**
 * @param {string} key
 * @param {string|object} value
 * @returns the key/value pair removed from localstorage
 */
export const popValueFromLocalStorage = (key) => {
  const value = getValueFromLocalStorage(key);
  if (value) {
    removeValueFromLocalStorage(key);
  }
  return value;
};

/**
 * Removes all key/value pairs in local storage.
 * @param {Array} allKeys list of keys to remove from local storage
 */
export const removeAllValuesInLocalStorage = (allKeys) => {
  Object.keys(allKeys).forEach((key) => {
    removeValueFromLocalStorage(key);
  });
};

export const clearAllFromLocalStorage = () => {
  window.localStorage.clear();
};

/**
 * Sets value in a local storage with an expiry date provided in seconds
 * @param {string} key
 * @param {string|object} value
 * @param {number} ttl
 */
export const setValueInLocalStorageWithExpiry = (key, value, ttl) => {
  const now = new Date();

  const item = {
    value,
    expiry: now.getTime() + ttl * 1000,
  };

  setValueInLocalStorage(key, item);
};

/**
 * `JSON.stringify` stored value
 * @param {string} key
 */
export const getValueFromLocalStorageWithExpiry = (key) => {
  const item = getValueFromLocalStorage(key);

  if (!item) {
    return null;
  }

  const now = new Date();

  if (now.getTime() > item.expiry) {
    removeValueFromLocalStorage(key);
    return null;
  }

  return item.value;
};

export const removeAllExpiredValuesFromLocalStorage = () => {
  Object.keys(localStorage).forEach((key) => {
    const item = getValueFromLocalStorage(key);
    const now = new Date();

    if (item?.expiry) {
      if (now.getTime() > item.expiry) {
        removeValueFromLocalStorage(key);
      }
    }
  });
};
