import styled from '@emotion/styled';
import { theme } from 'theme';
import { ignoredProps } from '../helpers';

const StyledBox = ({ paddingX, paddingY, marginX, marginY, width, display, ...props }) => {
  const padding = theme.spacing[props.padding];
  let paddingTop = theme.spacing[props.paddingTop];
  let paddingRight = theme.spacing[props.paddingRight];
  let paddingBottom = theme.spacing[props.paddingBottom];
  let paddingLeft = theme.spacing[props.paddingLeft];
  if (paddingX) {
    paddingLeft = theme.spacing[paddingX];
    paddingRight = theme.spacing[paddingX];
  }
  if (paddingY) {
    paddingTop = theme.spacing[paddingY];
    paddingBottom = theme.spacing[paddingY];
  }
  const margin = theme.spacing[props.margin];
  let marginTop = theme.spacing[props.marginTop];
  let marginRight = theme.spacing[props.marginRight];
  let marginBottom = theme.spacing[props.marginBottom];
  let marginLeft = theme.spacing[props.marginLeft];
  if (marginX) {
    marginLeft = theme.spacing[marginX];
    marginRight = theme.spacing[marginX];
  }
  if (marginY) {
    marginTop = theme.spacing[marginY];
    marginBottom = theme.spacing[marginY];
  }

  return {
    padding,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    margin,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    width,
    display,
  };
};

const boxConfig = ignoredProps(['display', 'width', 'spacing']);

export const Box = styled('div', boxConfig)(StyledBox);
