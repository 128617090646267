import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

function Spinner({ wrapperName }) {
  return (
    <div label="LoadingSpinner" className={wrapperName} data-testid="spinner">
      <div className="lds-ripple">
        <div />
        <div />
      </div>
    </div>
  );
}

Spinner.defaultProps = {
  wrapperName: 'spinner-wrapper',
};

Spinner.propTypes = {
  wrapperName: PropTypes.string,
};

export default Spinner;
