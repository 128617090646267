import styled from '@emotion/styled';
import { spacing, palette } from 'theme';

export const Navigation = styled.nav({
  background: palette.primary[100],
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.103266)',
});

export const NavList = styled.ul({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
});

export const NavItem = styled.li({
  paddingInline: spacing[8],
  paddingBlock: spacing[8],
});
