import styled from '@emotion/styled';
import { typography } from 'theme';
import { splitColour, ignoredProps } from '../helpers';

const variants = {
  h1: {
    fontFamily: typography.fonts.secondary.black,
    fontSize: typography.fontSizes['4xl'],
    lineHeight: '1',
  },
  h2: {
    fontFamily: typography.fonts.secondary.black,
    fontSize: typography.fontSizes['3xl'],
  },
  h3: {
    fontFamily: typography.fonts.secondary.black,
    fontSize: typography.fontSizes['2xl'],
    lineHeight: '1.1',
  },
  h3Bold: {
    fontFamily: typography.fonts.secondary.bold,
    fontSize: typography.fontSizes['2xl'],
  },
  h4: {
    fontFamily: typography.fonts.primary.bold,
    fontSize: typography.fontSizes.xl,
  },
  h5: {
    fontFamily: typography.fonts.primary.bold,
    fontSize: typography.fontSizes.xl,
  },
  body1: {
    fontFamily: typography.fonts.secondary.regular,
    fontSize: typography.fontSizes.l,
  },
  body1Bold: {
    fontFamily: typography.fonts.secondary.bold,
    fontSize: typography.fontSizes.l,
  },
  body2: {
    fontFamily: typography.fonts.secondary.regular,
    fontSize: typography.fontSizes.m,
  },
  body2Bold: {
    fontFamily: typography.fonts.secondary.bold,
    fontSize: typography.fontSizes.m,
  },
  label: {
    fontFamily: typography.fonts.primary.bold,
    fontSize: typography.fontSizes.xs,
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
  },
  pill: {
    fontFamily: typography.fonts.primary.bold,
    fontSize: typography.fontSizes.xs,
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
  errorRegular: {
    fontFamily: typography.fonts.secondary.regular,
    fontSize: typography.fontSizes.m,
  },
  errorSmall: {
    fontFamily: typography.fonts.secondary.regular,
    fontSize: typography.fontSizes.s,
  },
  '9xl': {
    fontFamily: typography.fonts.secondary.black,
    fontSize: typography.fontSizes['9xl'],
  },
};

const textAlignment = {
  center: {
    textAlign: 'center',
  },
  left: {
    textAlign: 'left',
  },
  right: {
    textAlign: 'right',
  },
  justify: {
    textAlign: 'justify',
  },
};

const textConfig = ignoredProps([
  'variant',
  'colour',
  'textAlign',
  'transform',
  'decoration',
  'lineHeight',
  'whiteSpace',
]);

export const Text = styled('p', textConfig)(
  ({ textAlign }) => textAlignment[textAlign],
  ({ colour }) => `color: ${splitColour(colour)};`,
  ({ transform }) => `text-transform: ${transform};`,
  ({ decoration }) => `text-decoration: ${decoration};`,
  ({ lineHeight }) => `line-height: ${lineHeight};`,
  ({ whiteSpace }) => `white-space: ${whiteSpace};`,
  ({ variant }) => variants[variant],
);
