import React from 'react';
import {
  getValueFromLocalStorageWithExpiry,
  setValueInLocalStorageWithExpiry,
  removeAllExpiredValuesFromLocalStorage,
} from 'utils';
import { useApplications, useVerified } from 'features/dashboard/api';
import { useAuth } from '../authContext/AuthContext';

const UserContext = React.createContext();
UserContext.displayName = 'UserContext';

function UserProvider(props) {
  const { user } = useAuth();
  const { data } = useVerified();
  const { data: applicationData } = useApplications();

  const isVerified = React.useMemo(() => {
    const localStorageKey = user.id;
    const lsValue = getValueFromLocalStorageWithExpiry(localStorageKey);
    removeAllExpiredValuesFromLocalStorage();

    if (lsValue?.verified) return true;

    if (data?.isVerified) {
      setValueInLocalStorageWithExpiry(localStorageKey, { ...lsValue, verified: true }, 604800);
      return true;
    }

    return false;
  }, [data, user.id]);

  const hasSubmitted = React.useMemo(() => {
    const localStorageKey = user.id;
    const listOfRestrictedStatuses = ['awaiting application form', 'discontinued'];
    const lsValue = getValueFromLocalStorageWithExpiry(localStorageKey);
    removeAllExpiredValuesFromLocalStorage();

    if (lsValue?.submitted) return true;

    const userSubmitted = applicationData?.some(
      (application) => !listOfRestrictedStatuses.includes(application.status.toLowerCase()),
    );

    if (userSubmitted) {
      setValueInLocalStorageWithExpiry(localStorageKey, { ...lsValue, submitted: true }, 604800);
      return true;
    }

    return false;
  }, [applicationData, user.id]);

  const dateInCorrectFormat = user?.birthdate?.split('-')?.reverse()?.join('-');

  const modifiedUser = {
    ...user,
    birthdate: dateInCorrectFormat,
    isVerified,
    hasSubmitted,
  };

  return <UserContext.Provider value={modifiedUser} {...props} />;
}

function useUser() {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error(`useUser must be used within a UserProvider`);
  }
  return context;
}

export { UserProvider, useUser };
