import styled from '@emotion/styled';
import { breakpoints, palette, spacing } from 'theme';

export const Container = styled.div(
  {
    zIndex: 0,
    position: 'relative',
    backgroundColor: palette.neutral[200],
    height: '100%',
    display: 'grid',
    gridTemplateRows: `auto 1fr`,
  },
  ({ shouldBackgroundApply }) =>
    shouldBackgroundApply && {
      [`${breakpoints.mobile}`]: {
        '&::after': {
          zIndex: -2,
          position: 'absolute',
          content: "''",
          height: '100%',
          width: '100%',
          backgroundAttachment: 'fixed',
          backgroundColor: palette.neutral[300],
          clipPath: `polygon(0 85%, 100% 65%, 100% 100%, 0% 100%)`,
        },
      },
    },
);

export const InnerContainer = styled.main(
  {
    height: '100%',
    paddingBottom: spacing[32],
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  ({ shouldOverflowDesktop }) =>
    shouldOverflowDesktop
      ? {
          [`${breakpoints.desktopDown}`]: {
            paddingBottom: spacing[12],
          },
        }
      : {
          [`${breakpoints.tabletDown}`]: {
            paddingBottom: spacing[12],
          },
        },
);
