import React from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary as Boundary } from 'react-error-boundary';
import { clearAllFromLocalStorage } from 'utils';
import { useQueryClient } from 'react-query';
import { trackException } from 'lib';
import Error from '../error/Error';
import * as S from './styles';

function ErrorFallback({ resetErrorBoundary }) {
  return (
    <S.Wrapper role="alert">
      <Error
        iconName="sad_face"
        label="Error loading page"
        mainText="Sorry, something went wrong."
        buttonLabel="Try again"
        handleClick={() => resetErrorBoundary()}
      />
    </S.Wrapper>
  );
}

ErrorFallback.propTypes = {
  resetErrorBoundary: PropTypes.func,
};

ErrorFallback.defaultProps = {
  resetErrorBoundary: () => {},
};

function ErrorBoundary({ children }) {
  const queryClient = useQueryClient();

  function reportError(error, info) {
    trackException({
      error,
      properties: { ...info },
    });
  }

  function handleReset() {
    clearAllFromLocalStorage();
    queryClient.removeQueries();
    window.location.replace('/');
  }
  return (
    <Boundary
      FallbackComponent={ErrorFallback}
      onError={(error, info) => reportError(error, info)}
      onReset={() => handleReset()}
    >
      {children}
    </Boundary>
  );
}

export default ErrorBoundary;
