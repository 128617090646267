import React from 'react';
import PropTypes from 'prop-types';
import { useIcon } from 'hooks';
import { ICONS } from 'assets/icons';
import { splitColour } from '../helpers';

const SvgIcon = ({ color, name, title, height, width, viewBox, className }) => {
  const Icon = useIcon(name);

  const additionalProps = {};
  if (height) additionalProps.height = height;
  if (width) additionalProps.width = width;
  if (viewBox) additionalProps.viewBox = viewBox;

  return (
    Icon && (
      <Icon
        label="Icon"
        title={title}
        aria-hidden={!title}
        color={splitColour(color)}
        role="img"
        data-testid={`svg-icon-${name}`}
        className={className}
        {...additionalProps}
      />
    )
  );
};

SvgIcon.defaultProps = {
  color: 'currentColor',
  name: null,
  title: null,
  height: null,
  width: null,
  viewBox: null,
  className: '',
};

SvgIcon.propTypes = {
  color: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.oneOf(ICONS), PropTypes.string]),
  title: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  viewBox: PropTypes.string,
  className: PropTypes.string,
};

export default SvgIcon;
