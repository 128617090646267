import React from 'react';
import PropTypes from 'prop-types';
import { useAuth } from 'context';

const PrivateContent = ({ children }) => {
  const auth = useAuth();

  return auth.isUserAuthenticated ? <>{children}</> : <></>;
};

PrivateContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
};

export default PrivateContent;
