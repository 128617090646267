import styled from '@emotion/styled';
import { typography, palette, spacing, borderRadius } from 'theme';
import { ignoredProps } from '../helpers';

const baseStyle = {
  whiteSpace: 'nowrap',
  verticalAlign: 'middle',
  width: 'fit-content',
  fontFamily: typography.fonts.primary.medium,

  userSelect: 'none',
  WebkitUserSelect: 'none',
  KhtmlUserSelect: 'none',
  MozUserSelect: 'none',
  msUserSelect: 'none',

  display: 'inline-flex',
  gap: spacing[4],
  alignItems: 'center',
};

const colourSchemeVariants = {
  grey: {
    solid: {
      color: palette.neutral[100],
      backgroundColor: palette.neutral[700],
    },
    outline: {
      color: palette.neutral[700],
      boxShadow: `${palette.neutral[700]} 0px 0px 0px 1px inset`,
    },
    subtle: {
      color: palette.neutral[700],
      backgroundColor: palette.neutral[300],
    },
    ghost: {
      color: palette.neutral[700],
    },
  },
  green: {
    solid: {
      color: palette.neutral[100],
      backgroundColor: palette.primary[700],
    },
    outline: {
      color: palette.primary[700],
      boxShadow: `${palette.primary[700]} 0px 0px 0px 1px inset`,
    },
    subtle: {
      color: palette.neutral[700],
      backgroundColor: palette.primary[200],
    },
    ghost: {
      color: palette.primary[700],
    },
  },
  red: {
    solid: {},
    outline: {},
    subtle: {},
    ghost: {
      color: palette.secondary[900],
    },
  },
};

const sizeVariants = {
  sm: {
    paddingInline: '2px',
    paddingBlock: '2px',
  },
  md: {
    paddingInline: spacing[8],
    paddingBlock: '6px',
  },
  lg: {
    paddingInline: spacing[16],
    paddingBlock: spacing[8],
  },
};

const badgeConfig = ignoredProps([
  'colourScheme',
  'size',
  'radius',
  'variant',
  'fontSize',
  'letterSpace',
  'font',
  'fontWeight',
  'textTransform',
]);

export const Badge = styled('span', badgeConfig)(
  {
    ...baseStyle,
  },
  ({ size }) => sizeVariants[size],
  ({ colourScheme, variant }) => colourSchemeVariants[colourScheme][variant],
  ({ radius }) => `border-radius: ${borderRadius[radius]};`,
  ({ fontSize }) => `font-size: ${typography.fontSizes[fontSize]};`,
  ({ font, fontWeight }) => `font-family: ${typography.fonts[font][fontWeight]};`,
  ({ letterSpace }) => `letter-spacing: ${letterSpace};`,
  ({ textTransform }) => `text-transform: ${textTransform};`,
);
