import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

function Image({ src, alt, width, height, fit, radius, className }) {
  return (
    <S.Image
      className={className}
      src={src}
      alt={alt}
      $fit={fit}
      $width={width}
      height={height}
      radius={radius}
    />
  );
}

export default Image;

Image.defaultProps = {
  alt: 'Image',
  width: '100%',
  height: '100%',
  fit: 'fill',
  radius: '0',
  src: null,
};

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  fit: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  radius: PropTypes.string,
};
