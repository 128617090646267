import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

function Button({ className, type, label, handleClick, size, width, variant, disabled, ...rest }) {
  return (
    <S.Button
      className={className}
      label="Button"
      type={type === 'button' ? 'button' : 'submit'}
      size={size}
      variant={variant}
      disabled={disabled}
      onClick={handleClick}
      width={width}
      data-testid="button"
      {...rest}
    >
      {label}
    </S.Button>
  );
}

Button.defaultProps = {
  className: '',
  type: 'button',
  size: 'md',
  handleClick: null,
  variant: 'primary',
  width: 'fit-content',
  disabled: false,
};

Button.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  handleClick: PropTypes.func,
  size: PropTypes.string,
  variant: PropTypes.string,
  width: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Button;
