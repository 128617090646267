import _ from 'lodash';

const optionDefaults = { reverse: false, ignoredKeys: [] };

function mapKeysToValues(object, objectWithKeysMap, options) {
  const opts = { ...optionDefaults, ...options };
  const objectWithIgnoredKeys = _.omit(object, opts.ignoredKeys);

  if (opts.reverse) {
    const swappedMap = _.invert(objectWithKeysMap);

    return _.omit(
      _.mapKeys(objectWithIgnoredKeys, (_value, key) => swappedMap[key] && swappedMap[key]),
      ['undefined'],
    );
  }

  return _.omit(
    _.mapKeys(
      objectWithIgnoredKeys,
      (_value, key) => objectWithKeysMap[key] && objectWithKeysMap[key],
    ),
    ['undefined'],
  );
}

export default mapKeysToValues;
