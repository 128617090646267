import React, { Suspense } from 'react';
import { useUser, useAuth } from 'context';
import { Navigate, Outlet } from 'react-router-dom';
import { Container } from 'components';
import { LoadingSpinner } from 'components/elements';

const Profile = React.lazy(() => import('../pages/profile/Profile'));

function ProtectedProfile() {
  const { isVerified, hasSubmitted } = useUser();
  const { isUserAuthenticated } = useAuth();

  const isVerifedButNotSubmitted = isVerified && !hasSubmitted;

  if ((isUserAuthenticated && isVerifedButNotSubmitted) || !isUserAuthenticated) {
    return <Navigate to="." />;
  }

  return (
    <Container>
      <Suspense fallback={<LoadingSpinner />}>
        <Outlet />
      </Suspense>
    </Container>
  );
}

export const protectedProfileRoute = [
  {
    path: '/',
    element: <ProtectedProfile />,
    children: [{ path: '/profile', element: <Profile /> }],
  },
];
