/**
 * Utility to convert digits to hexadecimals
 * @param {string|number} digit - value to be converted to hex
 * @returns {string}
 */
export const digitToHex = (digit) => digit.toString(16).padStart(2, '0');

/**
 * Utility for random string generator.
 * @param {string} length length of generated string, default is 16
 * @returns {string}
 */
export const randomString = (length = 16) => {
  let len = Number.parseInt(length, 10);
  if (Number.isNaN(len)) {
    if (typeof length === 'string') {
      len = length.length;
    } else {
      len = 16;
    }
  }
  const arr = new Uint8Array(len / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, digitToHex).join('');
};
