import styled from '@emotion/styled';
import { breakpoints } from 'theme';

export const EmailPhoneWrapper = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  [`${breakpoints.tabletDown}`]: {
    flexDirection: 'column',
  },
});
