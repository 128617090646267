import { useEffect, useRef, useState } from 'react';
import { ICONS } from 'assets/icons';

const isValidIcon = (name) => ICONS.includes(name);

/**
 * Dynamic svg icon import
 * @param {string} name Icon name
 * @returns
 */
const useIcon = (name) => {
  const [Icon, setIcon] = useState(null);
  const mountedRef = useRef(true); // true when component mounted

  useEffect(() => {
    const loadIcon = async () => {
      const importedIcon = await import(
        `!!@svgr/webpack?-svgo,+titleProp,+ref!../../assets/icons/${name}.svg`
      );
      if (!mountedRef.current) return; // skip state changes if component unmounted
      setIcon(importedIcon.default);
    };
    if (isValidIcon(name)) loadIcon();

    return () => {
      mountedRef.current = false; // false when component unmounted
    };
  }, [name]);

  return Icon;
};

export default useIcon;
