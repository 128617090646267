import React from 'react';
import PropTypes from 'prop-types';
import { cx, css } from '@emotion/css';
import * as S from './styles';

const variantsMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  body1: 'p',
  body2: 'p',
  label: 'span',
  labelUppercase: 'span',
  buttonLarge: 'span',
  buttonLargeBold: 'span',
  buttonSmall: 'span',
  pill: 'span',
};

function Text({
  as,
  className,
  variant,
  colour,
  textAlign,
  transform,
  decoration,
  lineHeight,
  whiteSpace,
  children,
  cssOverrides,
  ...rest
}) {
  return (
    <S.Text
      label="Text"
      as={as || variantsMapping[variant]}
      className={cx(
        {
          className,
        },
        css({
          ...cssOverrides,
        }),
      )}
      variant={variant}
      colour={colour}
      textAlign={textAlign}
      transform={transform}
      decoration={decoration}
      lineHeight={lineHeight}
      whiteSpace={whiteSpace}
      data-testid="text-component"
      {...rest}
    >
      {children}
    </S.Text>
  );
}

Text.defaultProps = {
  as: null,
  className: '',
  variant: 'body1',
  colour: 'neutral.800',
  textAlign: 'left',
  transform: '',
  decoration: 'none',
  lineHeight: '1.5',
  whiteSpace: 'normal',
  cssOverrides: null,
};

Text.propTypes = {
  as: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string,
  colour: PropTypes.string,
  textAlign: PropTypes.string,
  transform: PropTypes.string,
  decoration: PropTypes.string,
  lineHeight: PropTypes.string,
  whiteSpace: PropTypes.string,
  cssOverrides: PropTypes.shape({}),
};

export default Text;
