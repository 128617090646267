import {
  getCookie,
  decodeJwtToken,
  getValueFromLocalStorage,
  popValueFromLocalStorage,
  randomString,
  removeValueFromLocalStorage,
  setValueInLocalStorage,
} from 'utils';
import { authConfig } from 'authConfig';
import { updateAppInsightsUser, updateDataLayer } from 'lib/appInsights/appInsights';

export const TOKEN_TYPE = {
  ACCESS_TOKEN: 'id_token',
  DESTINATION_PATH: 'destinationPath',
  STATE: 'authState',
};

export const cookieType = {
  SESSION_ID: 'ai_session',
};

export const getAuthTokenFromLocalStorage = () => getValueFromLocalStorage(TOKEN_TYPE.ACCESS_TOKEN);

const getDataFromToken = (token) => {
  try {
    const [, encPayload] = token.split('.');
    const payloadStr = atob(encPayload);
    const payload = JSON.parse(payloadStr);
    const { oid, acr } = payload;
    return { oid, acr };
  } catch (ex) {
    return null;
  }
};

export const setAuthTokenInLocalStorage = (token) => {
  setValueInLocalStorage(TOKEN_TYPE.ACCESS_TOKEN, token);
  const tokenInfo = getDataFromToken(token);
  let oid;
  let acr;
  if (tokenInfo) {
    oid = tokenInfo.oid;
    acr = tokenInfo.acr;
  }
  updateAppInsightsUser(oid);
  if (
    window.dataLayer &&
    !window.dataLayer.filter((e) => ['logged_in', 'signed_up'].includes(e.event)).length
  ) {
    if (acr === 'b2c_1a_signup') {
      updateDataLayer('signed_up', oid);
    }
    updateDataLayer('logged_in', oid);
  }
};

export const removeAuthTokenFromLocalStorage = () => {
  removeValueFromLocalStorage(TOKEN_TYPE.ACCESS_TOKEN);
  updateAppInsightsUser(null);
};

export const createCachedUniqueString = (key) => {
  const value = randomString();
  setValueInLocalStorage(key, value);
  return value;
};

export const generateRequestState = () => createCachedUniqueString(TOKEN_TYPE.STATE);
export const retrieveRequestState = () => popValueFromLocalStorage(TOKEN_TYPE.STATE);
export const deleteRequestState = () => removeValueFromLocalStorage(TOKEN_TYPE.STATE);

export const appendOneTrustActiveCookieGroups = () => {
  const groups = window.OnetrustActiveGroups;
  return groups ? `&accepted_cookie_groups=${groups?.replace(',', '')}` : '';
};

export const getLoginUrlWithCachedState = () => {
  const state = generateRequestState();
  return `${authConfig.getSignInUrl()}&state=${state}${appendOneTrustActiveCookieGroups()}`;
};

export const getLoginUrlWithCachedSessionId = () => {
  const sessionId = getCookie(cookieType.SESSION_ID);
  return `${authConfig.getSignInUrl()}&state=${sessionId}${appendOneTrustActiveCookieGroups()}`;
};

const navigateToUrl = (url) => {
  window.location.replace(url);
};

export const redirectUserToLoginPage = () => {
  navigateToUrl(getLoginUrlWithCachedSessionId());
};

export const redirectUserToLogOutPage = () => {
  navigateToUrl(authConfig.logoutUrl);
};

export const storeDestinationPath = (path) => {
  if (!path || path === '/') return;
  setValueInLocalStorage(TOKEN_TYPE.DESTINATION_PATH, path);
};

export const retrieveDestinationPath = () => getValueFromLocalStorage(TOKEN_TYPE.DESTINATION_PATH);

export const removeDestinationPath = () => removeValueFromLocalStorage(TOKEN_TYPE.DESTINATION_PATH);

export const invalidateSessionAndForceLogin = () => {
  removeAuthTokenFromLocalStorage();
  deleteRequestState();
  redirectUserToLoginPage();
};

export const getValidTokenAndReturnState = () => {
  const token = getAuthTokenFromLocalStorage();

  if (!token) {
    return { authenticated: false };
  }

  const { isInvalid, isExpired, ...payload } = decodeJwtToken(token);
  if (isInvalid || isExpired) {
    return { authenticated: false };
  }

  return { authenticated: true, token, payload };
};
