import React from 'react';
import { SearchCriteriaProvider } from './useSearchCriteria/SearchCriteriaContext';
import GlobalThemeProvider from './globalThemeProvider/GlobalThemeProvider';
import { ImageProvider } from './imageProvider/useImage';
import { AuthProvider } from './authContext/AuthContext';
import { UserProvider } from './userContext/userContext';
import { AppInsightsProvider } from './AppInsights/AppInsights';
import ReactQueryProvider from './reactQueryProvider/ReactQueryProvider';
import ReactRouter from './ReactRouter';

const AppContext = ({ children }) => {
  return (
    <ReactRouter>
      <AppInsightsProvider>
        <GlobalThemeProvider>
          <ReactQueryProvider>
            <AuthProvider>
              <UserProvider>
                <ImageProvider>
                  <SearchCriteriaProvider>{children}</SearchCriteriaProvider>
                </ImageProvider>
              </UserProvider>
            </AuthProvider>
          </ReactQueryProvider>
        </GlobalThemeProvider>
      </AppInsightsProvider>
    </ReactRouter>
  );
};

export default AppContext;
