import React from 'react';
// TODO: import Theme Provider from reusable library
import { ThemeProvider, Global } from '@emotion/react';
import { globalStyles, theme } from 'theme';
import 'theme/fonts.css';

function GlobalThemeProvider({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <Global styles={globalStyles} />
      {children}
    </ThemeProvider>
  );
}

export default GlobalThemeProvider;
