import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

function Label({ htmlFor, children }) {
  return (
    <S.Label label="Label" htmlFor={htmlFor}>
      {children}
    </S.Label>
  );
}

Label.propTypes = {
  htmlFor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default Label;
