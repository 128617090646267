import React, { useState } from 'react';

const ImageContext = React.createContext(null);
ImageContext.displayName = 'ImageContext';

export function ImageProvider({ children }) {
  const [imageShouldBeRemoved, setImageShouldBeRemoved] = useState(false);
  const removeImagePreview = React.useCallback((value) => setImageShouldBeRemoved(value), []);

  const memoedValue = React.useMemo(
    () => ({
      removeImagePreview,
      imageShouldBeRemoved,
    }),
    [removeImagePreview, imageShouldBeRemoved],
  );

  return <ImageContext.Provider value={memoedValue}>{children}</ImageContext.Provider>;
}

export function useImage() {
  const context = React.useContext(ImageContext);
  if (!context) {
    throw new Error('useImage must be used within ImageProvider');
  }
  return context;
}
