import React from 'react';
import {
  getValueFromLocalStorage,
  setValueInLocalStorage,
  removeValueFromLocalStorage,
} from 'utils';

const SearchCriteriaContext = React.createContext(null);
SearchCriteriaContext.displayName = 'SearchCriteriaContext';

const successfulFirstFetch = getValueFromLocalStorage('successfulFirstFetch');

const initialPostcodeState = () => {
  if (!successfulFirstFetch) {
    removeValueFromLocalStorage('postcode');
    return '';
  }
  return getValueFromLocalStorage('postcode');
};

const initialState = {
  filters: [],
  radius: 20,
  postcode: initialPostcodeState(),
};

function searchCriteriaReducer(state, action) {
  switch (action.type) {
    case 'update postcode': {
      setValueInLocalStorage('postcode', action.postcode);
      return {
        ...state,
        postcode: action.postcode,
      };
    }
    case 'update radius': {
      return {
        ...state,
        radius: action.radius,
      };
    }
    case 'update filters': {
      return {
        ...state,
        filters: action.filters,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function SearchCriteriaProvider({ children }) {
  const [state, dispach] = React.useReducer(searchCriteriaReducer, initialState);

  const value = [state, dispach];

  return <SearchCriteriaContext.Provider value={value}>{children}</SearchCriteriaContext.Provider>;
}

function useSearchCriteria() {
  const context = React.useContext(SearchCriteriaContext);
  if (!context) {
    throw new Error('useSearchCriteria must be used within SearchCriteriaProvider');
  }
  return context;
}

export { SearchCriteriaProvider, useSearchCriteria };
