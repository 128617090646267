import { useQuery } from 'react-query';
import { axiosAuth } from 'lib';
import { useAuth } from 'context';

export const getVerified = () => {
  return axiosAuth.get(`/contact/verified`);
};

export const useVerified = () => {
  const { user, isUserAuthenticated } = useAuth();
  return useQuery(['verified', user.id], () => getVerified(), {
    enabled: isUserAuthenticated,
    retry: 2,
    staleTime: Infinity,
  });
};
