import React from 'react';
import PropTypes from 'prop-types';

function useInput(initialValue, options) {
  const [inputValue, setInputValue] = React.useState(() => initialValue);

  function handleChange(event) {
    const { name, value } = event.target;

    if (typeof options?.validate !== 'undefined' && !options?.validate(value)) return;

    if (typeof inputValue === 'object' && inputValue !== null && !Array.isArray(inputValue)) {
      setInputValue((prevValue) => ({ ...prevValue, [name]: value }));
    } else {
      setInputValue(value);
    }
  }

  function overrideState(value) {
    setInputValue(value);
  }

  return [inputValue, handleChange, overrideState];
}

useInput.defaultProps = {
  options: {},
};

useInput.propTypes = {
  initialValue: PropTypes.string.isRequired,
  options: PropTypes.shape({
    validate: PropTypes.func,
  }),
};

export default useInput;
