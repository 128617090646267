import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

const Checkbox = React.forwardRef(
  (
    {
      icon,
      id,
      isChecked,
      isDisabled,
      isInvalid,
      isReadOnly,
      name,
      onChange,
      value,
      className,
      children,
      variant,
      ...rest
    },
    ref,
  ) => {
    return (
      <S.Checkbox className={className}>
        <S.CheckboxInput
          ref={ref}
          type="checkbox"
          label="Checkbox"
          name={name}
          id={id}
          value={value}
          checked={isChecked}
          disabled={isDisabled}
          invalid={isInvalid}
          readOnly={isReadOnly}
          onChange={onChange}
          variant={variant}
          data-testid="common-checkbox"
          {...rest}
        />

        <S.Label htmlFor={id}>
          {icon && icon}
          <S.LabelText className="checkbox-label" text={children}>
            {children}
          </S.LabelText>
        </S.Label>
      </S.Checkbox>
    );
  },
);

Checkbox.defaultProps = {
  icon: null,
  isChecked: false,
  isDisabled: false,
  isInvalid: false,
  isReadOnly: false,
  value: '',
  children: '',
  className: '',
  onChange: () => {},
  name: null,
  variant: 'primary',
};

Checkbox.propTypes = {
  icon: PropTypes.element,
  id: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string,
};

export default Checkbox;
