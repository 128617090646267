import { theme } from 'theme';
import isPropValid from '@emotion/is-prop-valid';

function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0;
}

function splitColour(colour) {
  if (typeof colour !== 'string') {
    throw Error('Parameter is not a string');
  }

  const regexPattern = /[.]/g;
  const stringHasDot = regexPattern.test(colour);

  if (stringHasDot) {
    const splitString = colour.split('.');
    return theme.palette[splitString[0]][splitString[1]];
  }

  return colour;
}

function ignoredProps(propsArray) {
  if (!Array.isArray(propsArray)) {
    throw Error('Parameter is not an array');
  }

  return {
    shouldForwardProp: (prop) => isPropValid(prop) && !propsArray.includes(prop),
  };
}

export { isObjectEmpty, splitColour, ignoredProps };
