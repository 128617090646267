import styled from '@emotion/styled';
import { spacing, borderRadius, breakpoints } from 'theme';

export const Error = styled.div({
  height: '100%',
  borderRadius: borderRadius.lg,
  display: 'grid',
  placeItems: 'center',
  paddingBlock: spacing[12],
});

export const InnerWrapper = styled.div({
  textAlign: 'center',
  width: 'min(40ch, 95%)',
});

export const TextWrapper = styled.div({
  marginTop: spacing[40],

  [`${breakpoints.mobileDown}`]: {
    marginTop: '0',
  },

  '& > * + *': {
    marginBlock: `${spacing[16]} ${spacing[28]}`,
  },
});
