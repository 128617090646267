import styled from '@emotion/styled';
import { spacing, typography, palette, borderRadius, effects } from 'theme';

export const Accordion = styled.div({
  backgroundColor: palette.neutral[100],
  borderRadius: borderRadius.lg,
});

export const Button = styled.button({
  width: '100%',
  paddingInline: spacing[12],
  paddingBlock: spacing[12],
  fontFamily: typography.fonts.secondary.bold,
  fontSize: typography.fontSizes.m,
  color: palette.primary[700],
  backgroundColor: palette.neutral[100],
  border: 'none',
  borderRadius: borderRadius.lg,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',

  '.left-icon': {
    display: 'flex',
    alignSelf: 'center',
  },

  '&[aria-expanded="false"] .left-icon': {
    transition: `all ${effects.transition}`,
    transform: 'rotate(180deg)',
  },
  '&[aria-expanded="true"] .left-icon': {
    transition: `all ${effects.transition}`,
    transform: 'rotate(0)',
  },
});

export const Collapse = styled.div(
  {
    transition: `all ${effects.slide}`,
    overflowY: 'hidden',
  },
  ({ isOpen }) =>
    isOpen
      ? {
          opacity: 1,
          maxHeight: '800px',
        }
      : {
          maxHeight: '0px',
          opacity: 0,
        },
);
