import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

function Textarea({
  className,
  value,
  onChange,
  placeholder,
  size,
  variant,
  colourScheme,
  height,
  width,
  resize,
  isDisabled,
  isInvalid,
  isReadOnly,
  ...rest
}) {
  return (
    <S.Textarea
      className={className}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      size={size}
      variant={variant}
      colourScheme={colourScheme}
      height={height}
      width={width}
      resize={resize}
      disabled={isDisabled}
      invalid={isInvalid}
      readOnly={isReadOnly}
      data-testid="textarea-component"
      {...rest}
    />
  );
}

Textarea.defaultProps = {
  className: '',
  value: '',
  placeholder: '',
  size: 'md',
  variant: 'outline',
  colourScheme: 'primary',
  height: 'auto',
  width: '100%',
  resize: 'none',
  isDisabled: false,
  isInvalid: false,
  isReadOnly: false,
};

Textarea.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  colourScheme: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  resize: PropTypes.string,
  isDisabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isReadOnly: PropTypes.bool,
};

export default Textarea;
