import React from 'react';
import { useTrackEvent } from '@microsoft/applicationinsights-react-js';
import { useAppInsights } from 'context';
import { useSearchParams } from 'react-router-dom';

const utmTagsList = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
  'utm_id',
];

function useTrackUtmTags() {
  const appInsights = useAppInsights();

  const [searchParams] = useSearchParams();
  const trackUmtTags = useTrackEvent(appInsights, 'UTM');

  const utmTags = React.useMemo(() => {
    return utmTagsList.reduce((obj, tag) => {
      const urlParamValue = searchParams.get(tag);

      if (urlParamValue) return { ...obj, [tag]: urlParamValue };

      return obj;
    }, {});
  }, [searchParams]);

  React.useEffect(() => {
    if (Object.keys(utmTags).length > 0) {
      trackUmtTags(utmTags);
    }
  }, [trackUmtTags, utmTags]);
}

export default useTrackUtmTags;
