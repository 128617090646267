import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useUser } from 'context';
import { Text } from 'components/elements';
import { useGetImage } from 'api';
import useGetProfileImage from '../../../api/useGetProfileImage';

import * as S from './styles';

function Avatar({ name }) {
  const navigate = useNavigate();
  function redirectToProfile() {
    navigate('/profile');
  }
  const { data } = useGetProfileImage();
  const value = data?.value;
  const profilePhotoValue = useGetImage(value, {
    retry: 2,
    staleTime: Infinity,
    enabled: !!value,
  });

  const { givenName, surname } = useUser();
  return (
    <S.Profile type="button" data-testid="avatar" onClick={() => redirectToProfile()}>
      <Text variant="body2Bold" colour="primary.700">
        {name}
      </Text>

      {profilePhotoValue.data?.data.downloadUri ? (
        <S.UserProfilePhoto
          src={profilePhotoValue.data?.data.downloadUri}
          alt="User Profile Photo"
        />
      ) : (
        <S.UserInitials>
          <Text variant="h3" colour="primary.700" textAlign="center">
            {`${givenName?.charAt(0)}${surname?.charAt(0)}`}
          </Text>
        </S.UserInitials>
      )}
    </S.Profile>
  );
}

Avatar.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Avatar;
