import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components/elements';
import PrivateContent from 'components/privateContent/PrivateContent';
import * as S from './styles';

function NavigationLink({ label, route, exact }) {
  return (
    <S.NavItem key={route}>
      <Link variant="nav" to={route} exact={exact}>
        <span className="nav-link__text">{label}</span>
      </Link>
    </S.NavItem>
  );
}

NavigationLink.propTypes = {
  label: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  exact: PropTypes.string.isRequired,
};

function Navigation({ links }) {
  return (
    <S.Navigation label="Navigation" data-testid="navigation">
      <S.NavList>
        {links.map((link) =>
          link.private ? (
            <PrivateContent key={link.label}>
              <NavigationLink {...link} />
            </PrivateContent>
          ) : (
            <NavigationLink {...link} key={link.label} />
          ),
        )}
      </S.NavList>
    </S.Navigation>
  );
}

Navigation.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      route: PropTypes.string,
      iconName: PropTypes.string,
    }),
  ).isRequired,
};

export default Navigation;
