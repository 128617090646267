import React from 'react';
import { useWindowSize } from 'hooks';
import { ErrorBoundary, Toast } from 'components';
import { AppContext } from 'context';
import { AppRoutes } from 'routes';
import { initializeGTM } from 'lib';

initializeGTM();

function App() {
  const size = useWindowSize();

  React.useEffect(() => {
    const root = document.querySelector('.root') || document.querySelector('#root');

    if (root) root.style.height = `${size.height}px`;
  }, [size]);

  return (
    <AppContext>
      <ErrorBoundary>
        <Toast />
        <AppRoutes />
      </ErrorBoundary>
    </AppContext>
  );
}

export default App;
