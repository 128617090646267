import styled from '@emotion/styled';
import { palette, borderRadius, breakpoints, spacing } from 'theme';
import { ignoredProps, splitColour } from '../elements/helpers';

const portalConfig = ignoredProps(['colour']);

export const Modal = styled(
  'div',
  portalConfig,
)({
  width: '100vw',
  height: '100vh',
  '@supports(height: -webkit-fill-available)': {
    height: '-webkit-fill-available',
  },
  position: 'fixed',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: '999',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const Overlay = styled.div(
  {
    position: 'fixed',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,

    width: '100vw',
    height: '100vh',

    opacity: 0.75,
  },
  ({ colour }) => `background-color: ${splitColour(colour)};`,
);

export const Content = styled.div({
  paddingBlock: spacing[52],
  paddingInline: spacing[96],

  position: 'relative',
  backgroundColor: palette.neutral[100],
  borderRadius: borderRadius.lg,
  display: 'flex',
  flexDirection: 'column',
  width: '41rem',

  [`${breakpoints.tabletDown}`]: {
    width: '90vw',
    paddingInline: spacing[32],
  },
});

export const CloseButton = styled.div({
  position: 'absolute',
  top: spacing[12],
  right: spacing[2],
});
export const BackgroundIcon = styled.div({
  position: 'absolute',
  bottom: spacing[32],
  left: spacing[32],

  [`${breakpoints.tabletDown}`]: {
    display: 'none',
  },
});
export const Body = styled.main({});
export const Footer = styled.footer({});
