import { useQuery } from 'react-query';
import { axiosAuth } from 'lib';
import { useAuth } from 'context';

export const getTrainings = () => {
  return axiosAuth.get(`/trainings/me`);
};

export const useTrainings = () => {
  const { user, isUserAuthenticated } = useAuth();
  return useQuery(['trainings', user.id], () => getTrainings(), {
    enabled: isUserAuthenticated,
    refetchOnWindowFocus: true,
    retry: 2,
    staleTime: 60 * 1000,
  });
};
