import styled from '@emotion/styled';
import { spacing, palette, borderRadius, breakpoints } from 'theme';

export const HelpBox = styled('div')(
  {
    backgroundColor: 'transparent',
    borderRadius: borderRadius.lg,
    padding: spacing[8],

    display: 'flex',
    gap: spacing[8],
    alignItems: 'center',
  },
  ({ myProfile }) =>
    !myProfile && {
      [`${breakpoints.tablet}`]: {
        backgroundColor: palette.neutral[300],
      },
    },
);
