import React from 'react';
import { AppInsightsContext, useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { initialiseAppInsights } from 'lib';

const appInsights = initialiseAppInsights();
let appInsightsReactPlugin;
if (appInsights != null) {
  appInsightsReactPlugin = appInsights.reactPlugin;
}

function AppInsightsProvider({ children }) {
  return (
    <AppInsightsContext.Provider value={appInsightsReactPlugin}>
      {children}
    </AppInsightsContext.Provider>
  );
}

function useAppInsights() {
  return useAppInsightsContext();
}

export { AppInsightsProvider, useAppInsights };
