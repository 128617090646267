import Axios from 'axios';
import { parseEnv, getValueFromLocalStorage } from 'utils';

// const environment = getEnvironmentFromUrl();
const baseUrl = parseEnv(`REACT_APP_BASE_URL`, `${window.location.origin}`);
const backendApi = parseEnv(`REACT_APP_API_URL`, `${baseUrl}/api`);

const axios = Axios.create({
  baseURL: backendApi,
});

axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // This will be used to pass notifications to notificationContext
    // const message = error.response?.data?.message || error.message;

    return Promise.reject(error);
  },
);

const axiosAuth = Axios.create({
  baseURL: backendApi,
});

axiosAuth.interceptors.request.use(
  (request) => {
    const token = getValueFromLocalStorage('id_token');

    if (token) {
      request.headers.common.Authorization = `bearer ${token}`;
    }

    return request;
  },
  (error) => Promise.reject(error),
);

axiosAuth.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => Promise.reject(error),
);

export { axios, axiosAuth };
