import styled from '@emotion/styled';
import { palette, spacing, borderRadius, effects, typography, boxShadow } from 'theme';
import { ignoredProps } from '../helpers';

const propsConfig = ignoredProps(['variant', 'size', 'colourScheme']);

export const SelectWrapper = styled.div({
  position: 'relative',
  width: 'fit-content',
});

/* 
  Value Container
*/

const valueContainerBaseStyles = {
  borderRadius: borderRadius.base,
  transition: `all ${effects.transition}`,
  display: 'flex',
  flexWrap: 'no-wrap',
  justifyContent: 'space-between',
  alignItems: 'center',

  '.left-icon': {
    marginLeft: spacing[4],
    display: 'flex',
  },

  '&[aria-expanded="false"] .left-icon': {
    transition: `all ${effects.transition}`,
    transform: 'rotate(180deg)',
  },
  '&[aria-expanded="true"] .left-icon': {
    transition: `all ${effects.transition}`,
    transform: 'rotate(0)',
  },
};

const valueContainerVariants = {
  outline: {
    border: '1px solid',
  },
};

const valueContainerSizes = {
  xs: {
    minWidth: '4ch',
    paddingInlineStart: spacing[12],
    paddingInlineEnd: spacing[4],
    paddingBlock: spacing[2],
  },
  sm: {
    height: '32px',
    minWidth: '96px',
    maxWidth: '100%',
    paddingInline: spacing[8],
  },
  md: {
    height: '32px',
    minWidth: '208px',
    maxWidth: '100%',
    paddingInline: spacing[8],
  },
};

const valueContainerColourScheme = {
  primary: {
    backgroundColor: palette.neutral[100],
    borderColor: palette.neutral[400],
    '&[aria-expanded="true"]': {
      borderColor: palette.neutral[700],
    },
  },
  secondary: {
    backgroundColor: palette.neutral[100],
    borderColor: palette.neutral[400],
    '&[aria-expanded="true"]': {
      borderColor: palette.neutral[700],
    },
  },
};

export const ValueContainer = styled('div', propsConfig)(
  {
    ...valueContainerBaseStyles,
  },
  ({ variant }) => valueContainerVariants[variant],
  ({ size }) => valueContainerSizes[size],
  ({ colourScheme }) => valueContainerColourScheme[colourScheme],
  ({ isInvalid }) => isInvalid && `border-color: ${palette.secondary[700]}`,
  ({ isDisabled }) =>
    isDisabled && {
      pointerEvents: 'none',
      backgroundColor: palette.neutral[300],
      cursor: 'not-allowed',

      '.left-icon': {
        opacity: 0,
      },
    },
);

/* 
  Value 
*/

const valueBaseStyles = {
  minWidth: 0,
  flex: 1,

  '& > * ': {
    userSelect: 'none',
    wekbitTouchCallout: 'none' /* iOS Safari */,
    webkitUserSelect: 'none' /* Safari */,

    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

const valueSizes = {
  xs: {
    fontFamily: typography.fonts.secondary.bold,
    fontSize: typography.fontSizes.m,
  },
  sm: {
    fontFamily: typography.fonts.secondary.regular,
    fontSize: typography.fontSizes.m,
  },
  md: {
    fontFamily: typography.fonts.secondary.regular,
    fontSize: typography.fontSizes.m,
  },
};

const valueColourScheme = {
  primary: {
    '& .placeholder': {
      color: palette.neutral[400],
    },
    '& .selected-value': {
      color: palette.neutral[800],
    },
  },
  secondary: {
    '& .placeholder': {
      color: palette.neutral[400],
    },
    '& .selected-value': {
      color: palette.primary[700],
    },
  },
};

export const Value = styled('div', propsConfig)(
  {
    ...valueBaseStyles,
  },
  ({ size }) => valueSizes[size],
  ({ colourScheme }) => valueColourScheme[colourScheme],
);

/*
  Option List
*/

const optionListBaseStyles = {
  width: '100%',
  minWidth: 'max-content',
  zIndex: 10,
  listStyle: 'none',
  textAlign: 'left',
  borderRadius: borderRadius.base,
  padding: 0,
  flexGrow: 1,
  overflow: 'auto',

  // Scroll
  scrollbarWidth: 'thin',
  scrollbarColor: palette.neutral[400],

  // Works on Chrome, Edge, and Safari
  '&::-webkit-scrollbar': {
    width: '4px',
    height: '4px',
  },

  '&::-webkit-scrollbar-thumb': {
    background: palette.neutral[300],
    borderRadius: '4px',
  },

  '&:hover': {
    '&::-webkit-scrollbar-thumb': {
      background: palette.neutral[400],
      bordeRadius: '4px',
    },
  },
};

const optionListSizes = {
  xs: {
    paddingBlock: spacing[8],
    paddingInlineEnd: `${spacing[12]}`,
    textAlign: 'right',
    marginTop: spacing[4],
    fontFamily: typography.fonts.secondary.regular,
    fontSize: typography.fontSizes.m,
  },
  sm: {
    maxHeight: '220px',
    paddingBlock: spacing[8],
    paddingInline: `${spacing[12]} ${spacing[12]}`,
    marginTop: spacing[4],
    fontFamily: typography.fonts.secondary.regular,
    fontSize: typography.fontSizes.m,
    '& > * + *': {
      marginTop: spacing[4],
    },
  },
  md: {
    maxHeight: '220px',
    paddingBlock: spacing[8],
    paddingInline: `${spacing[12]} ${spacing[12]}`,
    marginTop: spacing[4],
    fontFamily: typography.fonts.secondary.regular,
    fontSize: typography.fontSizes.m,

    '& > * + *': {
      marginTop: spacing[4],
    },
  },
};

const optionListColourScheme = {
  primary: {
    backgroundColor: palette.neutral[100],
    boxShadow: boxShadow.base,
  },
  secondary: {
    backgroundColor: palette.neutral[100],
  },
};

export const OptionList = styled('ul', propsConfig)(
  {
    ...optionListBaseStyles,
  },
  ({ size }) => optionListSizes[size],
  ({ colourScheme }) => optionListColourScheme[colourScheme],
);

/* 
  Option
*/

const optionBaseStyles = {
  cursor: 'pointer',
  fontFamily: 'inherit',
  fontSize: typography.fontSizes.m,
};

const optionColourScheme = {
  primary: {
    color: palette.neutral[600],
    '&:hover': {
      color: palette.primary[300],
    },

    '&[aria-selected="true"]': {
      fontFamily: typography.fonts.secondary.bold,
      color: palette.primary[700],
    },
  },
  secondary: {
    color: palette.neutral[600],

    '&[aria-selected="true"]': {
      fontFamily: typography.fonts.secondary.bold,
      color: palette.primary[700],
    },
  },
};

export const Option = styled('li', propsConfig)(
  {
    ...optionBaseStyles,
  },
  ({ colourScheme }) => optionColourScheme[colourScheme],
);

const errorBaseStyle = {
  position: 'absolute',
  width: '100%',
  marginTop: spacing[8],
  backgroundColor: palette.secondary[200],
  borderRadius: borderRadius.base,
  color: palette.secondary[900],
  display: 'flex',
  alignItems: 'center',
  gap: spacing[8],
};

const errorSizes = {
  md: {
    fontSize: typography.fontSizes.m,
    padding: `${spacing[8]} ${spacing[12]}`,
  },
};

const errorConfig = ignoredProps(['size']);

export const ErrorMessage = styled('p', errorConfig)(
  {
    ...errorBaseStyle,
  },
  ({ size }) => errorSizes[size],
);
