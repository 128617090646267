import React from 'react';
import { Toaster, ToastBar } from 'react-hot-toast';
import { palette, typography, spacing } from 'theme';

function Toast() {
  return (
    <Toaster
      position="top-center"
      toastOptions={{
        duration: 6000,
        style: {
          backgroundColor: palette.neutral[900],
          fontFamily: typography.fonts.secondary.regular,
          fontSize: typography.fontSizes.m,
          paddingInline: spacing[2],
          border: '1px solid',
          borderColor: palette.neutral[400],
        },

        success: {
          style: {
            backgroundColor: palette.primary[200],
            color: palette.primary[500],
            borderColor: palette.primary[300],
          },
        },

        error: {
          style: {
            backgroundColor: palette.secondary[200],
            color: palette.secondary[900],
            borderColor: palette.secondary[500],
          },
        },
      }}
    >
      {/* TODO: Check with Nacho if he wants icons. If yes, they need to be added here */}
      {(t) => <ToastBar toast={t}>{({ message }) => <>{message}</>}</ToastBar>}
    </Toaster>
  );
}

export default Toast;
