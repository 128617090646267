import styled from '@emotion/styled';
import { theme } from 'theme';
import { ignoredProps } from '../helpers';

const baseStyle = {
  display: 'inline-block',
  fontFamily: theme.typography.fonts.secondary.bold,
  cursor: 'pointer',
  borderRadius: theme.borderRadius.lg,
  border: 'none',
  transition: `all ${theme.effects.transition}`,

  '&:disabled': {
    cursor: 'not-allowed',
  },
};

const sizes = {
  md: {
    fontSize: theme.typography.fontSizes.m,
    padding: `${theme.spacing[8]} ${theme.spacing[16]}`,
  },
  sm: {
    fontSize: theme.typography.fontSizes.xs,
    padding: `${theme.spacing[6]} ${theme.spacing[8]}`,
  },
  xs: {
    fontSize: theme.typography.fontSizes.xs,
    padding: `${theme.spacing[8]} ${theme.spacing[0]}`,
  },
  none: {
    fontSize: theme.typography.fontSizes.m,
    padding: theme.spacing[0],
  },
};

const colorVariants = {
  primary: {
    backgroundColor: theme.palette.secondary[700],
    color: theme.palette.neutral[100],
    '&:hover': {
      backgroundColor: theme.palette.secondary[500],
    },
    '&:active': {
      backgroundColor: theme.palette.secondary[900],
    },
    '&:disabled': {
      backgroundColor: theme.palette.neutral[300],
    },
  },
  secondary: {
    backgroundColor: theme.palette.primary[200],
    color: theme.palette.primary[700],
    '&:hover': {
      backgroundColor: theme.palette.primary[300],
    },
    '&:active': {
      backgroundColor: theme.palette.primary[900],
      color: theme.palette.neutral[100],
    },
    '&:disabled': {
      backgroundColor: theme.palette.neutral[300],
      color: theme.palette.neutral[100],
      cursor: 'not-allowed',
    },
  },
  error: {
    backgroundColor: 'transparent',
    color: theme.palette.secondary[900],
    textTransform: 'uppercase',
  },
  ghost: {
    backgroundColor: 'transparent',
    color: theme.palette.primary[700],
    textTransform: 'uppercase',
    letterSpacing: '1px',

    '&:hover': {
      color: theme.palette.primary[300],
    },
    '&:active': {
      color: theme.palette.primary[900],
    },
    '&:disabled': {
      color: theme.palette.neutral[300],
    },
  },
  invertGhost: {
    backgroundColor: 'transparent',
    color: theme.palette.primary[300],
    textTransform: 'uppercase',
    letterSpacing: '1px',

    '&:hover': {
      color: theme.palette.primary[700],
    },
    '&:active': {
      color: theme.palette.primary[900],
    },
  },

  helpGhostRed: {
    backgroundColor: 'transparent',
    color: theme.palette.secondary[700],
  },

  helpGhostGreen: {
    backgroundColor: 'transparent',
    color: theme.palette.primary[700],
  },
};

const buttonConfig = ignoredProps(['variant', 'size', 'width']);

export const Button = styled('button', buttonConfig)(
  {
    ...baseStyle,
  },
  ({ variant }) => colorVariants[variant],
  ({ size }) => sizes[size],
  ({ width }) => `width: ${theme.sizes[width]}`,
);
