import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

function BulletPoints({ items }) {
  return (
    <S.BulletPointsList label="bullet-points-list">
      {items.map((item) => (
        <S.BulletPoint>{item}</S.BulletPoint>
      ))}
    </S.BulletPointsList>
  );
}

BulletPoints.propTypes = {
  items: PropTypes.arrayOf([PropTypes.string, PropTypes.number]).isRequired,
};

export default BulletPoints;
