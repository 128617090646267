import styled from '@emotion/styled';
import { theme } from 'theme';
import { ignoredProps } from '../helpers';

const baseStyle = {
  border: 'none',
  outline: 'none',
  fontFamily: theme.typography.fonts.secondary.regular,
  borderRadius: theme.borderRadius.base,
  transition: `all ${theme.effects.transition}`,

  '::-webkit-inner-spin-button': {
    display: 'none',
  },

  '::-webkit-outer-spin-button': {
    display: 'none',
  },

  '&:[type="number"]': {
    MozAppearance: 'textfield',
  },

  '&:disabled': {
    cursor: 'not-allowed',
  },
  MozAppearance: 'textfield !important',
};

const sizes = {
  md: {
    width: '100%',
    minWidth: '100px',
    height: '32px',
    fontSize: theme.typography.fontSizes.m,
    paddingInline: theme.spacing[8],
  },
  reg_sm: {
    minWidth: '44px',
    height: '32px',
    fontSize: theme.typography.fontSizes.m,
    paddingInline: theme.spacing[8],
  },
  sm: {
    minWidth: '44px',
    height: '32px',
    fontSize: theme.typography.fontSizes.m,
    paddingInline: theme.spacing[4],
    textAlign: 'center',
  },
};

const colorVariants = {
  primary: {
    borderWidth: theme.spacing.px,
    borderStyle: 'solid',
    borderColor: theme.palette.neutral[400],
    backgroundColor: theme.palette.neutral[100],
    color: theme.palette.neutral[800],
    '::placeholder': {
      color: theme.palette.neutral[400],
    },
    '&:hover:enabled': {
      borderColor: theme.palette.primary[300],
    },
    '&:active:enabled': {
      borderColor: theme.palette.neutral[700],
    },
    '&:disabled': {
      backgroundColor: theme.palette.neutral[300],
    },
    '&:readonly': {
      backgroundColor: theme.palette.neutral[300],
    },
  },
};

const inputConfig = ignoredProps(['size', 'variant']);

export const Input = styled('input', inputConfig)(
  {
    ...baseStyle,
  },
  ({ size }) => sizes[size],
  ({ variant }) => colorVariants[variant],
  ({ invalid }) => invalid && `border-color: ${theme.palette.secondary[700]}`,
);
