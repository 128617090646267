import styled from '@emotion/styled';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { palette, typography, effects, spacing } from 'theme';

const baseStyle = {
  cursor: 'pointer',
  textDecoration: 'none',
  transition: `all ${effects.transition}`,
};

const variants = {
  nav: {
    position: 'relative',
    top: '2px',
    fontFamily: typography.fonts.primary.bold,
    fontSize: typography.fontSizes.xs,
    textTransform: 'uppercase',
    letterSpacing: typography.letterSpacing[0.5],
    color: palette.primary[700],
    borderBottom: `2px solid transparent`,

    display: 'flex',
    flexDirection: 'column',
    gap: spacing[4],

    '&.active': {
      color: palette.primary[900],
      borderBottom: `2px solid ${palette.primary[900]}`,
    },
  },

  navMobile: {
    fontFamily: typography.fonts.primary.bold,
    fontSize: typography.fontSizes.xs,
    textTransform: 'uppercase',
    letterSpacing: typography.letterSpacing[0.5],
    color: palette.primary[700],
    borderBottom: '2px solid transparent',

    display: 'flex',
    flexDirection: 'column',
    gap: spacing[4],

    '&.active': {
      color: palette.primary[900],
      borderBottom: `2px solid ${palette.primary[900]}`,
    },
  },

  link: {
    fontFamily: typography.fonts.secondary.regular,
    fontSize: typography.fontSizes.m,
    color: palette.neutral[600],

    '& > .link__icon': {
      marginRight: spacing[4],
      display: 'inline-block',
      color: palette.primary[700],
      transform: 'rotate(270deg)',
    },

    '&:hover': {
      color: palette.primary[300],

      '& > .link__icon': {
        color: palette.primary[300],
      },
    },

    '&:active': {
      color: palette.primary[900],

      '& > .link__icon': {
        color: palette.primary[900],
      },
    },
  },

  linkPrimaryNormal: {
    fontFamily: typography.fonts.secondary.bold,
    fontSize: typography.fontSizes.m,
    color: palette.primary[700],

    '& > .link__icon': {
      marginLeft: spacing[4],
      display: 'inline-block',
      color: palette.primary[700],
      transform: 'rotate(90deg)',
    },

    '&:hover': {
      color: palette.primary[300],

      '& > .link__icon': {
        color: palette.primary[300],
      },
    },

    '&:active': {
      color: palette.primary[900],

      '& > .link__icon': {
        color: palette.primary[900],
      },
    },
  },

  linkPrimarySmall: {
    fontFamily: typography.fonts.primary.medium,
    fontSize: typography.fontSizes.xs,
    color: palette.primary[700],

    '& > .link__icon': {
      marginRight: spacing[4],
      display: 'inline-block',
      color: palette.primary[700],
      transform: 'rotate(270deg)',
    },

    '&:hover': {
      color: palette.primary[300],

      '& > .link__icon': {
        color: palette.primary[300],
      },
    },

    '&:active': {
      color: palette.primary[900],

      '& > .link__icon': {
        color: palette.primary[900],
      },
    },
  },
};

export const Link = styled.a(
  {
    ...baseStyle,
  },
  ({ variant }) => variants[variant],
);

export const RouterLink = styled(RouterNavLink)(
  {
    ...baseStyle,
  },
  ({ variant }) => variants[variant],
);
