import React, { Suspense } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Container } from 'components';
import { LoadingSpinner } from 'components/elements';
import { storeDestinationPath } from 'helpers';
import { useAuth } from 'context';

export const Dashboard = React.lazy(() => import('../pages/dashboard/Dashboard'));
export const Application = React.lazy(() => import('../pages/application/Application'));

function Protected() {
  const { isUserAuthenticated } = useAuth();
  const location = useLocation();

  if (!isUserAuthenticated) {
    storeDestinationPath(location.pathname);
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <Container>
      <Suspense fallback={<LoadingSpinner />}>
        <Outlet />
      </Suspense>
    </Container>
  );
}

export const protectedRoutes = [
  {
    path: '/',
    element: <Protected />,
    children: [
      { path: '/dashboard', element: <Dashboard /> },
      { path: '/opportunities/:id/application', element: <Application /> },
    ],
  },
];
