import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  removeAuthTokenFromLocalStorage,
  getValidTokenAndReturnState,
  setAuthTokenInLocalStorage,
  retrieveDestinationPath,
  removeDestinationPath,
  storeDestinationPath,
} from 'helpers';
import { urlHashParams } from 'utils';

const AuthContext = React.createContext();
AuthContext.displayName = 'AuthContext';

function initialState(location, setUserData) {
  const params = urlHashParams(location);

  if (params.id_token) {
    setAuthTokenInLocalStorage(params.id_token);
  }

  const tokenAndState = getValidTokenAndReturnState();

  if (tokenAndState.authenticated) {
    setUserData(tokenAndState.payload);
    setAuthTokenInLocalStorage(tokenAndState.token);
    return true;
  }

  removeAuthTokenFromLocalStorage();

  return false;
}

function AuthProvider(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [userData, setUserData] = React.useState({});
  const [isUserAuthenticated, setIsUserAuthenticated] = React.useState(() =>
    initialState(location, setUserData),
  );

  React.useEffect(() => {
    const destinationPath = retrieveDestinationPath();

    if (isUserAuthenticated && destinationPath) {
      removeDestinationPath();
      if (destinationPath === '/opportunities') {
        navigate('/dashboard');
      } else {
        navigate(destinationPath);
      }
    }
  }, [isUserAuthenticated, navigate]);

  React.useEffect(() => {
    const tokenAndState = getValidTokenAndReturnState();
    if (!tokenAndState.authenticated) {
      setIsUserAuthenticated(false);
      removeAuthTokenFromLocalStorage();
    }
  }, [location]);

  function logout() {
    removeAuthTokenFromLocalStorage();
    setIsUserAuthenticated(false);
    setUserData({});
    navigate('/opportunities');
    toast.success(`You've successfully logged out`);
  }

  function login() {
    storeDestinationPath(location.pathname);
    navigate('/login');
  }

  function signup() {
    // TODO: Add signup
  }

  const value = {
    user: userData,
    isUserAuthenticated,
    login,
    logout,
    signup,
  };

  return <AuthContext.Provider value={value} {...props} />;
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth, AuthContext };
