import { useQuery } from 'react-query';
import { axiosAuth } from 'lib';
import { useAuth } from 'context';

export const getApplications = () => {
  return axiosAuth.get(`/applications/me`);
};

export const useApplications = () => {
  const { user, isUserAuthenticated } = useAuth();
  return useQuery(['applications', user.id], () => getApplications(), {
    enabled: isUserAuthenticated,
    refetchOnWindowFocus: true,
    retry: 2,
  });
};
