/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Input, Label, Text, Button, Box } from 'components/elements';
import { useInput, useAxios, useDebounce } from 'hooks';
import { axiosAuth } from 'lib';
import * as S from './styles';

function AddressLookup({ onAddressSelect, onManualSelect, isDisabled }) {
  const [lookupValue, setLookupValue, overrideLookupValue] = useInput('');
  const { data: addresses, isSuccess, isLoading, run } = useAxios();

  useDebounce(
    () => {
      if (lookupValue.length > 0) {
        run(axiosAuth.get(`address/autocomplete/${lookupValue}`));
      }
    },
    200,
    [lookupValue],
  );

  function updateAddressData(address) {
    overrideLookupValue('');
    onAddressSelect(address);
  }

  function focusOnInput() {
    overrideLookupValue('');
    onManualSelect();
  }

  return (
    <S.AddressLookup label="AddressLookup">
      <Label htmlFor="AddressLookupInput">Find address</Label>
      <S.InnerContainer>
        <Box paddingTop={8} paddingBottom={8}>
          <Text variant="body2" colour="neutral.600">
            Enter your postcode and add your house number or any other extra info to narrow your
            search.
          </Text>
        </Box>
        <Input
          type="text"
          placeholder="Enter new address"
          value={lookupValue}
          onChange={(e) => setLookupValue(e)}
          tabIndex="0"
          id="AddressLookupInput"
          isDisabled={isDisabled}
        />
      </S.InnerContainer>

      {isLoading && lookupValue.length > 0 ? (
        <S.Listbox>
          <Text variant="body2" colour="neutral.700">
            Loading...
          </Text>
        </S.Listbox>
      ) : null}

      {isSuccess && addresses?.length === 0 && lookupValue.length > 0 ? (
        <S.Listbox>
          <Text variant="body2" colour="neutral.700">
            Sorry, we can’t find this address.
          </Text>
          <Button
            label="Enter address manually"
            size="xs"
            variant="ghost"
            handleClick={() => focusOnInput()}
          />
        </S.Listbox>
      ) : null}

      {isSuccess && addresses?.length > 0 && lookupValue.length > 0 ? (
        <S.Listbox tabIndex={-1}>
          {addresses?.map((address) => (
            <S.ListItem key={address.addressText}>
              <S.ListButton type="button" onClick={() => updateAddressData(address)}>
                {address.addressText}
              </S.ListButton>
            </S.ListItem>
          ))}
          <Box paddingTop={8}>
            <Text variant="body2" colour="neutral.700">
              Can’t find your address?
            </Text>
            <Button
              label="Enter address manually"
              size="xs"
              variant="ghost"
              handleClick={() => focusOnInput()}
            />
          </Box>
        </S.Listbox>
      ) : null}
    </S.AddressLookup>
  );
}

export default AddressLookup;

AddressLookup.defaultProps = {
  onManualSelect: () => {},
  isDisabled: false,
};

AddressLookup.propTypes = {
  onAddressSelect: PropTypes.func.isRequired,
  onManualSelect: PropTypes.func,
  isDisabled: PropTypes.bool,
};
