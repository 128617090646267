const validationRegex = {
  alphabets: /^[aA-zZ\s]+$/,
  nameInputs: /^[aA-zZ\-'\s]+$/,
  optionalNameInputs: /^$|^[aA-zZ\-'\s]+$/,
  alphanumeric: /^[a-z0-9]+$/i,
  alphaNumericWithDashSpaceAndUnderscore: /^[a-zA-Z0-9 _-]+$/,
  addressInputs: /^[\w\-\s]+$/i,
  numeric: /^[+]?[0-9]+$/,
  phoneNumber: /^[0-9,+]{9,13}$/,
  email:
    // eslint-disable-next-line no-control-regex
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
  postcode:
    /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/,
  // Source: https://gist.github.com/danielrbradley/7567269
  drivingLicense: /^[A-Z9]{5}\d{6}[A-Z9]{2}\d[A-Z]{2}$/,
};

export default validationRegex;
