import styled from '@emotion/styled';
import { spacing } from 'theme';

export const DatePicker = styled.div({
  width: 'fit-content',
});

export const InputsWrapper = styled.div({
  width: 'fit-content',
  whiteSpace: 'nowrap',

  '& .datepicker__day, & .datepicker__month': {
    width: '22px',
  },

  '& .datepicker__year': {
    width: '54px',
  },

  '& > * + *': {
    marginLeft: spacing[4],
  },
});
