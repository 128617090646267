import { useQuery } from 'react-query';
import { axiosAuth } from 'lib';
import { useAuth } from 'context';
import { getValidTokenAndReturnState } from 'helpers';
import { useNavigate } from 'react-router-dom';

export const getVolunteerElapsedTime = () => {
  return axiosAuth.get(`/applications/initial`);
};

export const useVolunteerElapsedTime = () => {
  const navigate = useNavigate();
  const { user, isUserAuthenticated } = useAuth();
  return useQuery(['initial', user.id], () => getVolunteerElapsedTime(), {
    enabled: isUserAuthenticated,
    refetchOnWindowFocus: true,
    retry: (failureCount) => {
      const tokenAndState = getValidTokenAndReturnState();
      if (!tokenAndState.authenticated && failureCount > 1) {
        navigate('/opportunities');
      } else if (failureCount < 3) return true;
      return false;
    },
    staleTime: 60 * 1000,
  });
};
