import { axiosAuth } from 'lib';
import { useQuery } from 'react-query';

export const getImage = (id) => {
  return axiosAuth.get(`/files/${id}?fields=id&fields=fileKey&fields=downloadUri`);
};

export const useGetImage = (id, options = { retry: 2, staleTime: Infinity }) => {
  return useQuery(['get-profile-image', id], () => getImage(id), options);
};
