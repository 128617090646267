import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'components/elements';
import { useGetImage } from 'api';
import * as S from './styles';

const ProfileImage = React.forwardRef(({ value, children, ...rest }, ref) => {
  const { data, isLoading } = useGetImage(value, {
    retry: 2,
    staleTime: Infinity,
  });

  return (
    <S.ProfileImage ref={ref} {...rest}>
      {!isLoading && data?.data?.downloadUri && (
        <Image src={data.data.downloadUri} alt="Profile image" />
      )}
    </S.ProfileImage>
  );
});

ProfileImage.propTypes = {
  value: PropTypes.string.isRequired,
};

export default ProfileImage;
