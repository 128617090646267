import styled from '@emotion/styled';
import { palette, borderRadius, effects, typography, spacing } from 'theme';
import { ignoredProps } from '../helpers';

export const Checkbox = styled.div({
  position: 'relative',
});

const colorVariants = {
  primary: {
    '&:checked + label .checkbox-label': {
      fontFamily: typography.fonts.secondary.bold,
      color: palette.primary[700],
    },
  },
  secondary: {
    '&:checked + label .checkbox-label': {
      color: palette.neutral[700],
    },
  },
};

const inputConfig = ignoredProps(['variant']);

export const CheckboxInput = styled('input', inputConfig)(
  {
    position: 'absolute',
    top: 2,
    left: 0,
    opacity: 0,
    height: '16px',
    width: '16px',

    '& + label::before': {
      position: 'absolute',
      content: '""',
      top: 2,
      left: 0,
      height: '16px',
      width: '16px',
      backgroundColor: palette.neutral[200],
      border: `1px solid`,
      borderColor: palette.neutral[400],
      borderRadius: borderRadius.base,
      transition: `all ${effects.transition}`,
    },

    '&:hover + label::before': {
      backgroundColor: palette.neutral[300],
      borderColor: palette.neutral[600],
    },

    '&:focus + label::before': {
      backgroundColor: palette.neutral[300],
      borderColor: palette.neutral[600],
    },

    '&:checked + label::before': {
      backgroundColor: palette.primary[200],
      borderColor: palette.primary[700],
    },

    '&:disabled + label::before': {
      backgroundColor: palette.neutral[100],
      borderColor: palette.neutral[200],
    },

    '& + label::after': {
      position: 'absolute',
      content: '""',
      top: 4,
      left: 5,
      height: '9px',
      width: '6px',
      opacity: 0,
      border: `2px solid ${palette.primary[700]}`,
      borderLeft: 0,
      borderTop: 0,
      transform: 'rotate(45deg)',
      transition: `opacity ${effects.transition}`,
    },

    '&:checked + label::after': {
      opacity: 1,
    },
  },
  ({ variant }) => colorVariants[variant],
);

export const Label = styled.label({
  marginLeft: '24px',
  fontFamily: typography.fonts.secondary.regular,
  fontSize: typography.fontSizes.m,
  color: palette.neutral[700],
  display: 'flex',
  alignItems: 'center',
  gap: spacing[8],
});

export const LabelText = styled.span`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &:after {
    content: '${({ text }) => `${text}`}';
    font-family: ${typography.fonts.secondary.bold};
    font-size: inherit;
    height: 0;
    visibility: hidden;
    overflow: hidden;
    user-select: none;
    pointer-events: none;
  }
`;
