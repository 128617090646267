import { useRoutes } from 'react-router-dom';
import { useTrackUtmTags } from 'hooks';
import { commonRoutes } from './Common';
import { protectedRoutes } from './Protected';
import { publicRoutes } from './Public';
import { protectedProfileRoute } from './ProtectedProfile';

function AppRoutes() {
  useTrackUtmTags();

  const element = useRoutes([
    ...protectedRoutes,
    ...protectedProfileRoute,
    ...publicRoutes,
    ...commonRoutes,
  ]);

  return element;
}

export default AppRoutes;
