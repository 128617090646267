import React from 'react';
import PropTypes from 'prop-types';
import { Label, Input } from 'components/elements';
import * as S from './styles';

function dateUpdateReducer(state, action) {
  switch (action.type) {
    case 'update day':
      if (action.payload.length > 2) return state;
      return { ...state, day: action.payload };
    case 'update month':
      if (action.payload.length > 2) return state;
      return { ...state, month: action.payload };
    case 'update year':
      if (action.payload.length > 4) return state;
      return { ...state, year: action.payload };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const initialState = (dateString) => {
  if (!dateString) {
    return {
      day: '',
      month: '',
      year: '',
    };
  }
  const dateMap = {
    0: 'day',
    1: 'month',
    2: 'year',
  };

  const objectifiedDate = dateString.split('-').reduce((dateObject, currentString, index) => {
    const copyOfDateObject = dateObject;
    copyOfDateObject[dateMap[index]] = currentString;

    return copyOfDateObject;
  }, {});

  return objectifiedDate;
};

const DatePicker = React.forwardRef(
  ({ label, selected, id, onDateChange, isInvalid, isDisabled }, ref) => {
    const [date, dispatch] = React.useReducer(dateUpdateReducer, initialState(selected));

    React.useEffect(() => {
      const stringifiedDate = Object.keys(date).reduce((dateString, currentKey) => {
        if (currentKey === 'day') {
          return date[currentKey];
        }

        if (currentKey !== 'day' && date[currentKey].length !== 0) {
          return dateString.concat(`-${date[currentKey]}`);
        }

        return dateString;
      }, '');

      if (stringifiedDate) onDateChange(stringifiedDate);
    }, [date, onDateChange]);

    return (
      <S.DatePicker label="DatePicker" data-testid="datePicker">
        <Label htmlFor={id}>{label}</Label>
        <S.InputsWrapper>
          <Input
            id={`${id}-day`}
            ref={ref}
            name="day"
            size="sm"
            className="datepicker__day"
            isDisabled={isDisabled}
            isInvalid={isInvalid}
            placeholder="DD"
            value={date.day}
            onChange={(event) => dispatch({ type: 'update day', payload: event.target.value })}
          />
          <Input
            id={`${id}-month`}
            ref={ref}
            name="month"
            size="sm"
            className="datepicker__month"
            isDisabled={isDisabled}
            isInvalid={isInvalid}
            placeholder="MM"
            value={date.month}
            onChange={(event) => dispatch({ type: 'update month', payload: event.target.value })}
          />
          <Input
            id={`${id}-year`}
            ref={ref}
            name="year"
            size="sm"
            className="datepicker__year"
            isDisabled={isDisabled}
            isInvalid={isInvalid}
            placeholder="YYYY"
            value={date.year}
            onChange={(event) => dispatch({ type: 'update year', payload: event.target.value })}
          />
        </S.InputsWrapper>
      </S.DatePicker>
    );
  },
);

DatePicker.defaultProps = {
  id: '',
  selected: '',
  isInvalid: false,
  isDisabled: false,
};

DatePicker.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  selected: PropTypes.string,
  onDateChange: PropTypes.func.isRequired,
  isInvalid: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

export default DatePicker;
