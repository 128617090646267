import React from 'react';

function useToggle(initialvalue = false) {
  const [state, setState] = React.useState(() => initialvalue);

  function toggle(value) {
    if (value) {
      setState(value);
    } else {
      setState((prevValue) => !prevValue);
    }
  }

  return [state, toggle];
}

export default useToggle;
