import React from 'react';
import PropTypes from 'prop-types';
import { Button, Text, SvgIcon } from 'components/elements';
import { useMediaQuery } from 'hooks';
import { breakpoints } from 'theme';
import HelpModal from '../helpModal/HelpModal';
import * as S from './styled';

function HelpBox({ myProfile, emailAddress }) {
  const isTablet = useMediaQuery(breakpoints.tablet);
  const [showModal, setShowModal] = React.useState(false);

  function showHelpModal() {
    setShowModal(true);
  }

  function closeHelpModal() {
    setShowModal(false);
  }

  return (
    <S.HelpBox label="HelpBox" myProfile={myProfile}>
      <SvgIcon name="exclamation" color="primary.700" />
      {isTablet && !myProfile ? (
        <>
          <Text variant="body2Bold" colour="primary.700" lineHeight="1">
            Need Help?
          </Text>
          <Button
            label="Click here"
            handleClick={() => showHelpModal()}
            variant="helpGhostRed"
            size="none"
          />
        </>
      ) : (
        <Button
          label="Need Help?"
          handleClick={() => showHelpModal()}
          variant="helpGhostGreen"
          size="none"
        />
      )}

      <HelpModal
        showModal={showModal}
        emailAddress={emailAddress}
        closeModal={() => closeHelpModal()}
      />
    </S.HelpBox>
  );
}

HelpBox.defaultProps = {
  myProfile: false,
  emailAddress: '',
};

HelpBox.propTypes = {
  myProfile: PropTypes.bool,
  emailAddress: PropTypes.string,
};

export default HelpBox;
