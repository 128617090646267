import palette from './palette';
import typography from './typography';
import spacing from './spacing';
import breakpoints from './breakpoints';
import borderRadius from './borderRadius';
import effects from './effects';
import boxShadow from './boxShadow';
import sizes from './sizes';
/**
 * NOTE: light and dark objects should be updated together
 * You can also add custom properties here like color shades, etc.
 * To learn more, see Default Theme example in folder readme
 */

const theme = {
  palette,
  typography,
  spacing,
  breakpoints,
  borderRadius,
  effects,
  boxShadow,
  ...sizes,
};
// const theme = {
//   light: {
//     palette: {
//       type: THEME_TYPE.LIGHT,
//       primary: {
//         main: palette.primary,
//         light: palette.primary,
//         dark: palette.white,
//       },
//       background: {
//         default: palette.backgroundWhite,
//         light: palette.backgroundWhite,
//         dark: palette.primary,
//       },
//     },
//     typography,
//     overrides: {
//       MuiCssBaseline: globalStyles,
//     },
//   },
//   dark: {
//     palette: {
//       type: THEME_TYPE.DARK,
//       primary: {
//         main: palette.white,
//         light: palette.white,
//         dark: palette.primary,
//       },
//       background: {
//         default: palette.primary,
//         light: palette.primary,
//         dark: palette.backgroundWhite,
//       },
//     },
//     typography,
//     overrides: {
//       MuiCssBaseline: globalStyles,
//     },
//   },
// };

export default theme;
