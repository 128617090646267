import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Text, Input, Label, Textarea, Button } from 'components/elements';
import { useINeedHelp } from 'api';
import { useInput, useMediaQuery } from 'hooks';
import { breakpoints, spacing } from 'theme';
import PropTypes from 'prop-types';
import validationRegex from 'features/application/constants/validationRegex';
import Modal from '../modal/Modal';
import ErrorMessage from '../errorMessage/ErrorMessage';
import * as S from './styled';

function HelpModal({ showModal, closeModal, emailAddress }) {
  const [helpMessage, setHelpMessage] = useInput({ description: '', telephone: '' });
  const [isSendClicked, setIsSendClicked] = React.useState(false);
  const isTablet = useMediaQuery(breakpoints.tablet);
  const { id } = useParams();
  const { mutate, isLoading } = useINeedHelp(id);

  const invalidMsg = helpMessage.description.length < 3;
  const invalidPhone = !validationRegex.phoneNumber.test(helpMessage.telephone);

  const handleSendHelp = () => {
    if (invalidMsg || invalidPhone) {
      setIsSendClicked(true);
    } else {
      mutate(
        {
          email: emailAddress,
          ...helpMessage,
        },
        {
          onSuccess: () => {
            closeModal(false);
          },
        },
      );
    }
  };

  const getPhoneNumberErrorMessage = (phoneNumber) => {
    if (phoneNumber.length > 13) {
      return 'Maximum 13 digits';
    }
    if (phoneNumber.length < 9) {
      return 'Minimum 9 digits';
    }
    return 'Invalid phone number';
  };

  return (
    <Modal isOpen={showModal} onClose={closeModal}>
      <Modal.Content>
        <Modal.CloseButton />

        <Modal.Header>What do you need help with?</Modal.Header>
        <Modal.Body>
          <Text variant="body2" colour="neutral.600" cssOverrides={{ paddingBottom: spacing[32] }}>
            Please give us your contact details and a sentence or two about what you need help with.
            Someone will review your request and get back to you soon.
          </Text>
          <S.EmailPhoneWrapper>
            <Box width={isTablet ? '45%' : '100%'}>
              <Label htmlFor="phoneNumber">Phone number</Label>
              <Input
                value={helpMessage.telephone}
                onChange={(e) => setHelpMessage(e)}
                type="number"
                placeholder="Enter your phone number"
                isInvalid={isSendClicked && invalidPhone}
                id="telephone"
                name="telephone"
              />
              {isSendClicked && invalidPhone && (
                <ErrorMessage variant="ghost">
                  {getPhoneNumberErrorMessage(helpMessage.telephone)}
                </ErrorMessage>
              )}
            </Box>
            <Box width={isTablet ? '45%' : '100%'}>
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                placeholder="Enter your email"
                value={emailAddress}
                isDisabled
                onChange={() => {}}
              />
            </Box>
          </S.EmailPhoneWrapper>

          <Box paddingTop={28} width="100%">
            <Label htmlFor="msg">Message</Label>
            <Textarea
              value={helpMessage.description}
              onChange={(e) => setHelpMessage(e)}
              placeholder="Write here how we can help you."
              height="7rem"
              isInvalid={isSendClicked && invalidMsg}
              name="description"
              id="description"
            />
            {isSendClicked && invalidMsg && (
              <ErrorMessage variant="ghost">Please add a message</ErrorMessage>
            )}
          </Box>
        </Modal.Body>
        <Modal.Footer cssOverrides={{ textAlign: 'right' }}>
          <Button handleClick={() => handleSendHelp()} label="Send" isDisabled={isLoading} />
        </Modal.Footer>
        <Modal.BackgroundIcon iconName="helpUser" />
      </Modal.Content>
    </Modal>
  );
}

HelpModal.defaultProps = {
  showModal: false,
  emailAddress: '',
};

HelpModal.propTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  emailAddress: PropTypes.string,
};

export default HelpModal;
