import styled from '@emotion/styled';
import { spacing, typography, palette, theme } from 'theme';
import { ignoredProps } from '../helpers';

export const RadioOptionsWrapper = styled.div`
  display: flex;
  margin-top: ${spacing[16]};
`;

export const RadioOption = styled.div`
  display: flex;
  margin-right: ${spacing[32]};
  align-items: center;
`;

const radioVariants = {
  primary: {
    '&:disabled::before': {
      boxShadow: `inset 1em 1em ${palette.neutral[400]}`,
    },

    '&:disabled': {
      borderColor: palette.neutral[400],
    },
  },
  secondary: {
    '&:disabled::before': {
      boxShadow: `inset 1em 1em ${palette.neutral[700]}`,
    },
    '&:disabled': {
      borderColor: palette.neutral[400],
    },
  },
};

const radioSizes = {
  sm: {
    width: '1em',
    height: '1em',
    '& + label': {
      fontSize: typography.fontSizes.s,
    },
    '&:checked': {
      '&::before': {
        width: '0.5em',
        height: '0.5em',
      },
    },
  },
  md: {
    width: '1.15em',
    height: '1.15em',
    '& + label': {
      fontSize: typography.fontSizes.m,
    },
    '&:checked': {
      '&::before': {
        width: '0.65em',
        height: '0.65em',
      },
    },
  },
  lg: {
    width: '1.5em',
    height: '1.5em',
    '& + label': {
      fontSize: typography.fontSizes.l,
    },
    '&:checked': {
      '&::before': {
        width: '1em',
        height: '1em',
      },
    },
  },
  xl: {
    width: '1.8em',
    height: '1.8em',
    '& + label': {
      fontSize: typography.fontSizes.xl,
    },
    '&:checked': {
      '&::before': {
        width: '1.2em',
        height: '1.2em',
      },
    },
  },
};

const inputConfig = ignoredProps(['variant', 'size', 'isInvalid']);

export const RadioOptionInput = styled('input', inputConfig)(
  {
    appearance: 'none',
    backgroundColor: palette.neutral[200],
    margin: 0,
    font: 'inherit',
    border: `0.15em solid ${palette.neutral[400]}`,
    borderRadius: theme.borderRadius.full,
    cursor: 'pointer',
    display: 'grid',
    placeContent: 'center',

    '& + label': {
      color: palette.neutral[500],
    },

    '&:checked': {
      borderColor: palette.primary[700],

      '& + label': {
        color: palette.primary[700],
        fontFamily: typography.fonts.secondary.bold,
      },

      '&::before': {
        content: '""',
        borderRadius: theme.borderRadius.full,
        transform: 'scale(1)',
        boxShadow: `inset 1em 1em ${palette.primary[700]}`,
      },

      '&:disabled::before': {
        boxShadow: `inset 1em 1em ${palette.neutral[400]}`,
      },
    },

    '&:disabled': {
      borderColor: palette.neutral[300],
      cursor: 'not-allowed',

      '& + label': {
        color: palette.neutral[400],
        cursor: 'not-allowed',
      },
    },
  },
  ({ variant }) => radioVariants[variant],
  ({ size }) => radioSizes[size],
  ({ isInvalid }) =>
    isInvalid && {
      borderColor: palette.secondary[700],
      '&:checked': {
        borderColor: palette.secondary[700],
      },
    },
);

export const RadioOptionLabel = styled.label`
  font-family: ${typography.fonts.secondary.regular};
  font-size: ${typography.fontSizes.m};
  cursor: pointer;
  padding-left: ${spacing[8]};
`;
