export const navigationRoutes = [
  {
    label: 'Dashboard',
    route: '/dashboard',
    iconName: 'nav_home',
    exact: 'true',
    private: true,
  },
  {
    label: 'Opportunities',
    route: '/opportunities',
    iconName: 'nav_opportunities',
    exact: 'false',
    private: false,
  },
];
