import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../svgIcon/SvgIcon';
import * as S from './styles';

function Accordion({ label, icon, children }) {
  const [isOpen, setIsOpen] = React.useState(false);

  function handleToggle() {
    setIsOpen((prevValue) => !prevValue);
  }

  return (
    <S.Accordion label="Accordion">
      <S.Button type="button" onClick={() => handleToggle()} aria-expanded={isOpen}>
        {label}

        {icon && <SvgIcon name="arrow" color="primary.700" className="left-icon" />}
      </S.Button>
      <S.Collapse isOpen={isOpen}>{children}</S.Collapse>
    </S.Accordion>
  );
}

Accordion.defaultProps = {
  label: '',
  icon: false,
  children: null,
};

Accordion.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.bool,
  children: PropTypes.node,
};

export default Accordion;
