import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

const Radio = React.forwardRef(
  (
    {
      id,
      isDisabled,
      isInvalid,
      isReadOnly,
      name,
      options,
      onChange,
      value,
      className,
      variant,
      size,
      ...rest
    },
    ref,
  ) => {
    return (
      <S.RadioOptionsWrapper className={className} {...rest}>
        {options.map((option) => (
          <S.RadioOption key={`${id}${option.value}`}>
            <S.RadioOptionInput
              onChange={isReadOnly ? null : onChange}
              type="radio"
              ref={ref}
              id={`${id}${option.label}`}
              name={`${id}${option.label}`}
              value={option.value}
              checked={option.value === value}
              disabled={isDisabled}
              isInvalid={isInvalid}
              readOnly={isReadOnly}
              variant={variant}
              size={size}
              data-testid={`${id}-radio-option-${option.value}`}
            />
            <S.RadioOptionLabel htmlFor={`${id}${option.label}`}>{option.label}</S.RadioOptionLabel>
          </S.RadioOption>
        ))}
      </S.RadioOptionsWrapper>
    );
  },
);

Radio.defaultProps = {
  options: [],
  value: null,
  onChange: null,
  isDisabled: false,
  isInvalid: false,
  isReadOnly: false,
  name: null,
  variant: 'primary',
  size: 'md',
};

Radio.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  value: PropTypes.string,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  name: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
};

export default Radio;
