const typography = {
  fonts: {
    // Fonts may change
    primary: {
      regular: 'Brandon-Regular',
      medium: 'Brandon-Medium',
      bold: 'Brandon-Bold',
    },
    secondary: {
      regular: 'Foco-Regular',
      bold: 'Foco-Bold',
      black: 'Foco-Black',
    },
  },
  fontSizes: {
    '9xl': '6rem',
    '4xl': '2.1736rem',
    '3xl': '1.5rem',
    '2xl': '1.375rem',
    xl: '1.125rem',
    l: '1rem', // 16px
    m: '0.875rem', // 14px
    s: '0.75rem', // 12px
    xs: '0.625rem', // 10px
  },
  lineheight: {
    base: 1.5,
  },
  letterSpacing: {
    normal: '0',
    0.5: '.5px',
    1: '1px',
  },
};

export default typography;
