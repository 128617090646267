/**
 * This is an array of strings containing the names of icons.
 * The name here **must** match the icon filename for dynamic import to work.
 * All icons in this folder used in this project should be listed here.
 */
export const ICONS = [
  'back',
  'camera',
  'circle_with_hole',
  'half_circle',
  'close',
  'logo',
  'dashboard_clock',
  'difference',
  'give_back',
  'friends',
  'help',
  'learn',
  'part_of_something',
  'error',
  'park',
  'sad_face',
  'home',
  'community',
  'hospital',
  'nav_home',
  'nav_opportunities',
  'arrow',
  'pin',
  'exclamation',
  'clock',
  'personTick',
  'tick',
  'emergencyContact',
  'steeringWheel',
  'link',
  'equality_and_diversity',
  'parent',
  'handshake',
  'court',
  'file',
  'helpUser',
  'xCross',
  'mobile_phone',
  'envelope',
  'contactDetails',
  'finger_print',
  'hands',
];

export { ReactComponent as BackIcon } from './back.svg';
export { ReactComponent as CircleWithHole } from './circle_with_hole.svg';
export { ReactComponent as HalfCircle } from './half_circle.svg';
export { ReactComponent as CloseIcon } from './close.svg';
export { ReactComponent as Camera } from './camera.svg';
export { ReactComponent as LogoIcon } from './logo.svg';
export { ReactComponent as DashboardClock } from './dashboard_clock.svg';
export { ReactComponent as DifferenceIcon } from './difference.svg';
export { ReactComponent as FriendsIcon } from './friends.svg';
export { ReactComponent as GiveBackIcon } from './give_back.svg';
export { ReactComponent as HelpIcon } from './help.svg';
export { ReactComponent as LearnIcon } from './learn.svg';
export { ReactComponent as PartOfSomethingIcon } from './part_of_something.svg';
export { ReactComponent as Error } from './error.svg';
export { ReactComponent as Park } from './park.svg';
export { ReactComponent as SadFace } from './sad_face.svg';
export { ReactComponent as House } from './home.svg';
export { ReactComponent as Community } from './community.svg';
export { ReactComponent as Hospital } from './hospital.svg';
export { ReactComponent as NavOpportunities } from './nav_opportunities.svg';
export { ReactComponent as NavHome } from './nav_home.svg';
export { ReactComponent as Arrow } from './arrow.svg';
export { ReactComponent as Pin } from './pin.svg';
export { ReactComponent as Exclamation } from './exclamation.svg';
export { ReactComponent as Clock } from './clock.svg';
export { ReactComponent as PersonTick } from './personTick.svg';
export { ReactComponent as Tick } from './tick.svg';
export { ReactComponent as EmergencyContact } from './emergencyContact.svg';
export { ReactComponent as SteeringWheel } from './steeringWheel.svg';
export { ReactComponent as Link } from './link.svg';
export { ReactComponent as EqualityAndDiversity } from './equality_and_diversity.svg';
export { ReactComponent as Parent } from './parent.svg';
export { ReactComponent as Handshake } from './handshake.svg';
export { ReactComponent as Court } from './court.svg';
export { ReactComponent as File } from './file.svg';
export { ReactComponent as HelpUser } from './helpUser.svg';
export { ReactComponent as xCross } from './xCross.svg';
export { ReactComponent as MobilePhone } from './mobile_phone.svg';
export { ReactComponent as Envelope } from './envelope.svg';
export { ReactComponent as ContactDetails } from './contactDetails.svg';
export { ReactComponent as FingerPrint } from './finger_print.svg';
export { ReactComponent as Hands } from './hands.svg';
