import styled from '@emotion/styled';
import { typography, palette, spacing } from 'theme';

export const Label = styled.label({
  fontFamily: typography.fonts.primary.bold,
  fontSize: typography.fontSizes.xs,
  textTransform: 'uppercase',
  letterSpacing: typography.letterSpacing[1],
  color: palette.neutral[600],
  marginLeft: spacing[8],
  marginBottom: spacing[4],
});
