import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

function Link({ to, href, iconLeft, iconRight, variant, isExternal, exact, children, ...rest }) {
  if (to)
    return (
      <S.RouterLink label="Link" to={to} variant={variant} exact={exact} {...rest}>
        {iconLeft && <span className="link__icon">{iconLeft}</span>}
        {children}
        {iconRight && <span className="link__icon">{iconRight}</span>}
      </S.RouterLink>
    );

  return (
    <S.Link label="Link" href={href} variant={variant} target={isExternal && '_blank'} {...rest}>
      {children}
      {iconRight && <span className="link__icon">{iconRight}</span>}
    </S.Link>
  );
}

Link.defaultProps = {
  to: '',
  href: '#',
  iconLeft: null,
  iconRight: null,
  isExternal: false,
  exact: 'false',
  variant: 'nav',
};

Link.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  isExternal: PropTypes.bool,
  exact: PropTypes.string,
  variant: PropTypes.string,
};

export default Link;
