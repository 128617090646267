import TagManager from 'react-gtm-module';
import { parseEnv } from 'utils';

// const environment = getEnvironmentFromUrl();

const gtmId = parseEnv(`REACT_APP_GOOGLE_TAG_MANAGER`);

const config = {
  gtmId,
};

export default () => TagManager.initialize(config);
