import React from 'react';
import PropTypes from 'prop-types';
import { Badge, SvgIcon } from 'components/elements';

function LocationBadge({ data }) {
  return (
    <Badge
      leftIcon={<SvgIcon name="pin" color="neutral.700" />}
      colourScheme="grey"
      size="sm"
      variant="ghost"
      fontSize="xs"
      textTransform="uppercase"
    >
      {data}
    </Badge>
  );
}

LocationBadge.propTypes = {
  data: PropTypes.string.isRequired,
};

export default LocationBadge;
