import React from 'react';
import { useQuery } from 'react-query';
import { axiosAuth } from 'lib';
import { useUser } from 'context';

function getProfileData() {
  return axiosAuth.get(`contact/profile`);
}

function useGetProfileImage() {
  const { id } = useUser();

  return useQuery(['profile', id], getProfileData, {
    staleTime: Infinity, // It prevents from refetching form in a background

    select: React.useCallback((data) => {
      const photoId = data.fields.find((field) => field.key === 'rvs_photoid');
      return photoId;
    }, []),
  });
}

export default useGetProfileImage;
