/** Define reusable palette according to project */
const palette = {
  neutral: {
    100: '#FFFFFF',
    150: '#FBFBFB',
    200: '#F6F6F6',
    300: '#ECECEC',
    400: '#CECECE',
    600: '#717171',
    700: '#515151',
    800: '#1F1F1F',
  },
  primary: {
    100: '#F2F7F7',
    200: '#CFE9E8',
    300: '#A1C8C4',
    400: '#259E96',
    700: '#007266',
    900: '#01463C',
  },
  secondary: {
    200: '#FFD4DE',
    500: '#EEB2C2',
    700: '#B90845',
    900: '#82003E',
  },
};

export default palette;
