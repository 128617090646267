/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Portal, SvgIcon, Text, Box } from 'components/elements';
import * as S from './styles';

const ModalContext = React.createContext(null);
ModalContext.displayName = 'ModalContext';

function useModalContext() {
  const context = React.useContext(ModalContext);
  if (!context) {
    throw new Error('useModalContext must be used within SearchCriteriaProvider');
  }
  return context;
}

function Modal({ children, isOpen, onClose, colour, ...restProps }) {
  return (
    <ModalContext.Provider value={{ isOpen, onClose }}>
      {isOpen ? (
        <Portal>
          <S.Overlay colour={colour} />
          <S.Modal {...restProps}>{children}</S.Modal>
        </Portal>
      ) : null}
    </ModalContext.Provider>
  );
}

Modal.defaultProps = {
  onClose: () => {},
};

Modal.propTypes = {
  colour: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

Modal.defaultProps = {
  colour: 'primary.700',
};

Modal.Content = function ModalContent({ children, ...restProps }) {
  const { isOpen } = useModalContext();
  return isOpen ? <S.Content {...restProps}>{children}</S.Content> : null;
};

Modal.Header = function ModalHeader({ children }) {
  const { isOpen } = useModalContext();

  return isOpen ? (
    <Text variant="body1Bold" colour="primary.700" textAlign="left">
      {children}
    </Text>
  ) : null;
};

Modal.CloseButton = function ModalCloseButton() {
  const { onClose, isOpen } = useModalContext();

  return isOpen ? (
    <S.CloseButton>
      <Button
        label={<SvgIcon name="close" color="primary.700" height="12px" />}
        variant="ghost"
        handleClick={() => onClose()}
      />
    </S.CloseButton>
  ) : null;
};

Modal.Body = function ModalBody({ children, ...restProps }) {
  const { isOpen } = useModalContext();
  return isOpen ? (
    <Box paddingTop={24} paddingBottom={52} {...restProps}>
      {children}
    </Box>
  ) : null;
};

Modal.Footer = function ModalFooter({ children, ...restProps }) {
  const { isOpen } = useModalContext();
  return isOpen ? <Box {...restProps}>{children}</Box> : null;
};

Modal.BackgroundIcon = function ModalBackgroundIcon({ iconName, ...restProps }) {
  const { isOpen } = useModalContext();
  return isOpen ? (
    <S.BackgroundIcon {...restProps}>
      <SvgIcon name={iconName} color="primary.200" />
    </S.BackgroundIcon>
  ) : null;
};

Modal.BackgroundIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
};

export default Modal;
