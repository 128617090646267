import React from 'react';
import ReactDOM from 'react-dom';
import App from 'app/App';
import { reportAxeCore, reportWebVitals } from 'utils';

/**
 * Drop strict mode in production to avoid material-ui duplicate style bug
 * See https://dev.azure.com/ans-devops/ANS-NULL-ANS-APPDEV_RND/_workitems/edit/9721
 * @returns App - React.StrictMode is discarded in production
 */
const AppMode = () =>
  process.env.NODE_ENV === 'production' ? (
    <App />
  ) : (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );

ReactDOM.render(<AppMode />, document.getElementById('root'));

reportAxeCore(React, ReactDOM);
reportWebVitals();
