import styled from '@emotion/styled';
import { palette, spacing, borderRadius } from 'theme';
import { ignoredProps } from 'components/elements/helpers';

const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  justifyContent: 'flex-start',
  gap: spacing[0],

  '& .error-text': {
    flexBasis: '80%',
  },
};

const errorVariants = {
  filled: {
    backgroundColor: palette.secondary[200],
    paddingBlock: spacing[8],
    paddingInline: spacing[12],
    borderRadius: borderRadius.base,
  },
  ghost: {
    marginTop: spacing[4],
  },
};

const errorMessageConfig = ignoredProps(['variant']);

export const MainErrorBody = styled.div({
  ...baseStyle,
  flexDirection: 'row',
  width: '100%',
  alignItems: 'flex-start',
});

export const SVGWrapper = styled.div({
  display: 'flex',
});

export const ErrorMessage = styled('div', errorMessageConfig)(
  {
    ...baseStyle,
  },
  ({ variant }) => errorVariants[variant],
);
