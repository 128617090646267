import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon, Text, Button } from 'components/elements';
import * as S from './styles';

const ErrorMessage = React.forwardRef(({ variant, children, optionalButton, ...rest }, ref) => {
  const errorVariants = {
    filled: {
      text: 'errorRegular',
      lineHeight: '1.3',
    },
    ghost: {
      text: 'errorSmall',
      lineHeight: '1.5',
    },
  };

  return (
    <S.ErrorMessage variant={variant} ref={ref} {...rest}>
      <S.MainErrorBody>
        <S.SVGWrapper>
          <SvgIcon name="error" color="secondary.700" width="25" height="25" viewBox="0 0 22 22" />
        </S.SVGWrapper>
        <Text
          className="error-text"
          variant={errorVariants[variant].text}
          colour="secondary.700"
          lineHeight={errorVariants[variant].lineHeight}
        >
          {children}
        </Text>
      </S.MainErrorBody>
      {!!Object.keys(optionalButton).length && <Button variant="error" {...optionalButton} />}
    </S.ErrorMessage>
  );
});

ErrorMessage.defaultProps = {
  variant: 'filled',
  optionalButton: {},
};

ErrorMessage.propTypes = {
  variant: PropTypes.string,
  optionalButton: PropTypes.shape({
    onClick: PropTypes.func,
    label: PropTypes.string,
    size: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
};

export default ErrorMessage;
