import styled from '@emotion/styled';
import { palette, spacing, breakpoints } from 'theme';

export const Header = styled.header({
  backgroundColor: palette.neutral[100],
  paddingBlock: `clamp(${spacing[4]}, 1vw, ${spacing[8]})`,

  paddingInline: `max(2vw, ${spacing[4]})`,
  display: 'flex',
  alignItems: 'center',

  '& > nav': {
    flex: '1 1 auto',
    marginInline: `${spacing[56]} ${spacing[12]}`,
  },

  [`${breakpoints.tabletDown}`]: {
    justifyContent: 'space-between',
  },
});
