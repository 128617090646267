import styled from '@emotion/styled';
import { palette } from 'theme';

export const Profile = styled.button`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0 0.5em;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
`;

export const Avatar = styled.div`
  min-height: 40px;
  min-width: 40px;
  border-radius: 50%;
`;

export const UserInitials = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${palette.primary[200]};
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
`;

export const UserProfilePhoto = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
