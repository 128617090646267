import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

const Input = React.forwardRef(
  (
    {
      type,
      onChange,
      onBlur,
      name,
      id,
      isInvalid,
      isDisabled,
      isReadOnly,
      placeholder,
      size,
      variant,
      isPureNumber,
      ...restProps
    },
    ref,
  ) => {
    const additionalProps = {};
    const numberInputSymbols = ['e', 'E', '-', '.'];
    if (isPureNumber) {
      additionalProps.onKeyDown = (evt) =>
        numberInputSymbols.includes(evt.key) && evt.preventDefault();
    }

    return (
      <S.Input
        label="Input"
        ref={ref}
        type={type}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        id={id}
        invalid={isInvalid}
        disabled={isDisabled}
        readOnly={isReadOnly}
        placeholder={placeholder}
        size={size}
        variant={variant}
        {...restProps}
        {...additionalProps}
        data-testid="input-field"
      />
    );
  },
);

Input.defaultProps = {
  type: 'text',
  placeholder: '',
  name: '',
  id: '',
  isInvalid: false,
  isDisabled: false,
  isReadOnly: false,
  size: 'md',
  variant: 'primary',
  onChange: null,
  onBlur: null,
  isPureNumber: false,
};

Input.propTypes = {
  type: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  isInvalid: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  size: PropTypes.string,
  variant: PropTypes.string,
  isPureNumber: PropTypes.bool,
};

export default Input;
