import React, { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { LoadingSpinner } from 'components/elements';
import { useAuth } from 'context';

const Login = React.lazy(() => import('../pages/login/Login'));

function Public() {
  const { isUserAuthenticated } = useAuth();

  if (isUserAuthenticated) {
    return <Navigate to="." />;
  }

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Outlet />
    </Suspense>
  );
}

export const publicRoutes = [
  {
    path: '/',
    element: <Public />,
    children: [{ path: 'login', element: <Login /> }],
  },
];
