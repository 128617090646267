import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { getEnvironmentFromUrl } from 'utils';

const environment = getEnvironmentFromUrl();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function ReactQueryProvider({ children }) {
  return (
    <QueryClientProvider client={queryClient}>
      {environment === 'DEV' || environment === 'LOCAL' ? (
        <ReactQueryDevtools initialIsOpen={false} />
      ) : null}
      {children}
    </QueryClientProvider>
  );
}

export default ReactQueryProvider;
