import { useMutation } from 'react-query';
import { toast } from 'react-hot-toast';
import { axiosAuth } from 'lib';

function setINeedHelp(id, formData) {
  const url = id ? `help/applications/${id}` : 'help/profile';
  return axiosAuth.post(url, formData);
}

export function useINeedHelp(opporunityId) {
  return useMutation((formData) => setINeedHelp(opporunityId, formData), {
    onMutate: () => {
      toast.loading('Sending...');
    },
    onError: () => {
      toast.error('Message failed to send');
    },
    onSuccess: () => {
      toast.success('Your message has been sent');
    },
  });
}
