import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import Header from '../header/Header';
import * as S from './styles';

const Container = ({ children }) => {
  const location = useLocation();

  function applyBackground() {
    const ignoredPaths = ['/opportunities', '/dashboard'];
    const { pathname } = location;

    return !ignoredPaths.includes(pathname);
  }

  function allowOverflow() {
    const allowedPaths = ['/dashboard'];
    const { pathname } = location;

    return allowedPaths.includes(pathname);
  }

  return (
    <S.Container
      label="Container"
      shouldBackgroundApply={applyBackground()}
      data-testid="common-container"
    >
      <Header />
      <S.InnerContainer shouldOverflowDesktop={allowOverflow()}>{children}</S.InnerContainer>
    </S.Container>
  );
};

export default Container;

Container.propTypes = {
  children: PropTypes.node.isRequired,
};
