import styled from '@emotion/styled';
import { typography, borderRadius, effects, spacing, palette } from 'theme';
import { ignoredProps } from '../helpers';

const baseStyle = {
  border: 'none',
  outline: 'none',
  fontFamily: typography.fonts.secondary.regular,
  borderRadius: borderRadius.base,
  transition: `all ${effects.transition}`,

  '&:disabled': {
    cursor: 'not-allowed',
  },
};

const textareaVariants = {
  outline: {
    border: '1px solid',
  },
  flushed: {},
  filled: {},
};

const textareaSizes = {
  sm: {},
  md: {
    paddingInline: spacing[8],
    paddingBlock: spacing[12],
    fontSize: typography.fontSizes.m,
  },
  lg: {},
};

const textareaColourScheme = {
  primary: {
    outline: {
      borderColor: palette.neutral[400],
      backgroundColor: palette.neutral[100],
      color: palette.neutral[800],
      '::placeholder': {
        color: palette.neutral[400],
      },
      '&:hover:enabled': {
        borderColor: palette.primary[300],
      },
      '&:active:enabled': {
        borderColor: palette.neutral[700],
      },
      '&:disabled': {
        backgroundColor: palette.neutral[300],
      },
      '&:readonly': {
        backgroundColor: palette.neutral[300],
      },
    },
  },
};

const textareaResize = {
  vertical: {
    resize: 'vertical',
  },
  horizontal: {
    resize: 'horizontal',
  },
  none: {
    resize: 'none',
  },
};

const textareaConfig = ignoredProps([
  'size',
  'variant',
  'colourScheme',
  'height',
  'width',
  'resize',
]);

export const Textarea = styled('textarea', textareaConfig)(
  { ...baseStyle },
  ({ variant }) => textareaVariants[variant],
  ({ size }) => textareaSizes[size],
  ({ colourScheme, variant }) => textareaColourScheme[colourScheme][variant],
  ({ resize }) => textareaResize[resize],
  ({ invalid }) => invalid && `border-color: ${palette.secondary[700]}`,
  ({ height }) => ({ height }),
  ({ width }) => ({ width }),
);
