import jwtdecode from 'jwt-decode';

export const getTime = (unixTime) => new Date(unixTime * 1000).getTime();

export const decodeJwtToken = (token) => {
  const decoded = {
    token: {},
    setToken(tkn) {
      this.token = tkn;
    },
  };
  try {
    const { givenName, surname, oid: id, birthdate, exp, email, dynamicsId } = jwtdecode(token);
    const isExpired = getTime(exp) <= Date.now();
    decoded.setToken({
      id,
      dynamicsId,
      givenName,
      surname,
      birthdate,
      isExpired,
      isInvalid: false,
      email,
    });
  } catch (err) {
    decoded.setToken({ isInvalid: true });
  }
  return { ...decoded.token };
};
