import React from 'react';
import * as S from './styles';

const ToggleSwitch = () => {
  return (
    <S.ToggleSwitch label="toggle" data-testid="common-toggle-switch">
      Toggle Switch
    </S.ToggleSwitch>
  );
};

ToggleSwitch.defaultProps = {
  baseColor: null,
  isSelected: false,
  sliderColor: null,
};

export default ToggleSwitch;
