import React from 'react';
import PropTypes from 'prop-types';
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  size as floatingSize,
} from '@floating-ui/react-dom';
import * as S from './styles';

const Select = React.forwardRef(
  (
    {
      value,
      onChange,
      options,
      placeholder,
      variant,
      size,
      colourScheme,
      isDisabled,
      isInvalid,
      leftIcon,
      ...rest
    },
    ref,
  ) => {
    const [areOptionsOpen, setAreOptionsOpen] = React.useState(false);
    const buttonRef = React.useRef(null);
    const { x, y, reference, floating, refs, strategy } = useFloating({
      placement: 'bottom-start',
      strategy: 'fixed',
      middleware: [
        offset(4),
        flip(),
        floatingSize({
          apply({ rects, elements }) {
            Object.assign(elements.floating.style, {
              width: `${rects.reference.width}px`,
            });
          },
        }),
      ],
      whileElementsMounted: autoUpdate,
    });

    function toggleOptionsOnClick() {
      setAreOptionsOpen((prevValue) => !prevValue);
    }

    function toggleOptionsOnKeyDown(event) {
      switch (event.key) {
        case 'SpaceBar':
        case 'Enter':
          event.preventDefault();
          setAreOptionsOpen((prevValue) => !prevValue);
          break;
        default:
          break;
      }
    }

    function selectOption(item) {
      onChange(item);
      setAreOptionsOpen(false);
      buttonRef.current.focus();
    }

    function selectOptionOnKeyDown(event, item) {
      switch (event.key) {
        case 'SpaceBar':
        case 'Enter':
          event.preventDefault();
          selectOption(item);
          break;
        default:
          break;
      }
    }

    React.useEffect(() => {
      const handler = (event) => {
        if (!refs.reference.current.contains(event.target) && areOptionsOpen) {
          setAreOptionsOpen(false);
        }
      };

      document.addEventListener('mousedown', handler);

      return () => {
        document.removeEventListener('mousedown', handler);
      };
    }, [areOptionsOpen, refs, setAreOptionsOpen]);

    return (
      <S.SelectWrapper
        label="Select"
        ref={reference}
        aria-haspopup="listbox"
        data-testid="select-component"
      >
        <S.ValueContainer
          tabIndex="0"
          role="button"
          onClick={() => toggleOptionsOnClick()}
          onKeyDown={(event) => toggleOptionsOnKeyDown(event)}
          isInvalid={isInvalid}
          isDisabled={isDisabled}
          variant={variant}
          ref={buttonRef}
          size={size}
          colourScheme={colourScheme}
          aria-expanded={areOptionsOpen}
        >
          <S.Value variant={variant} size={size} colourScheme={colourScheme} ref={ref}>
            {value ? (
              <div className="selected-value" disabled={isDisabled}>
                {value.label}
              </div>
            ) : (
              <div className="placeholder">{placeholder}</div>
            )}
          </S.Value>
          {leftIcon && <span className="left-icon">{leftIcon}</span>}
        </S.ValueContainer>

        {areOptionsOpen && (
          <S.OptionList
            tabIndex={-1}
            role="listbox"
            aria-activedescendant={options.id}
            aria-expanded={areOptionsOpen}
            size={size}
            colourScheme={colourScheme}
            ref={floating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
          >
            {options.map((option) => (
              <S.Option
                key={option.value}
                id={option.value}
                role="option"
                aria-selected={value && value.label === option.label}
                tabIndex={0}
                value={option.value}
                onClick={() => selectOption(option)}
                onKeyDown={(e) => selectOptionOnKeyDown(e, option)}
                colourScheme={colourScheme}
                {...rest}
              >
                {option.label}
              </S.Option>
            ))}
          </S.OptionList>
        )}
      </S.SelectWrapper>
    );
  },
);

Select.defaultProps = {
  value: null,
  isDisabled: false,
  isInvalid: false,
  variant: 'outline',
  size: 'md',
  colourScheme: 'primary',
  placeholder: '',
  leftIcon: null,
};

Select.propTypes = {
  value: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ).isRequired,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  variant: PropTypes.string,
  size: PropTypes.string,
  colourScheme: PropTypes.string,
  leftIcon: PropTypes.node,
};

export default Select;
