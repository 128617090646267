import styled from '@emotion/styled';

export const ProfileImage = styled.div({
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  borderRadius: '6px',
  display: 'flex',
  height: '140px',
  width: '110px',

  '& > img': {
    borderRadius: '6px',
    objectFit: 'cover',
  },
});
