/**
 * Get environment variable of type String or throws if missing without a default
 * @param {string} key name of environment variable
 * @param {string} defaultValue used if environment variable is missing
 * @returns string value of environment variable
 */
export const parseEnv = (key, defaultValue) => {
  // equality '==' check if null or undefined
  if (process == null || process.env == null) {
    throw new Error(`"process.env" is null or undefined!`);
  }
  if (!key || !process.env[key]) {
    // const bypass = process.env.NODE_ENV === 'test' || !!process.env.REACT_APP_DEV;
    // if (defaultValue == null && !bypass) {
    //   throw new Error(`Missing environment variable: ${key}`);
    // }
    return defaultValue;
  }
  return process.env[key];
};

/**
 * Get environment variable of type Number or throws if missing without a default
 * @param {string} key name of environment variable
 * @param {number} defaultValue used if environment variable is missing
 * @returns string value of environment variable
 */
export const parseEnvNumber = (key, defaultValue) => {
  const number = Number.parseInt(parseEnv(key, `${defaultValue}`), 10);
  if (Number.isNaN(number)) {
    throw new Error(`Bad environment variable: ${key} - not a number`);
  }
  return number;
};

/**
 * Get environment variable of type String or throws if missing without a default
 * @param {string} key name of environment variable
 * @param {boolean} defaultValue used if environment variable is missing
 * @returns string value of environment variable
 */
export const parseEnvBoolean = (key, defaultValue) =>
  parseEnv(key, `${defaultValue}`).toString().toLowerCase() === 'true';

/**
 * Retrieves envrionment from url, e.g `https://[environment].domainname.tld`.
 * When `NODE_ENV = production`, environment could be `DEV | TEST | UAT | PROD`
 * @returns string value of environment
 */
export const getEnvironmentFromUrl = () => {
  const url = window.location.host;
  const [environment] = url.split('.');
  if (environment === 'my') return 'PROD';
  if (environment === 'dev') return 'DEV';
  if (environment === 'test') return 'TEST';
  if (environment === 'uat') return 'UAT';
  if (environment === 'dat') return 'DAT';
  if (environment === 'pre') return 'PREPROD';
  if (process.env.NODE_ENV !== 'production') return 'LOCAL';
  return environment.toUpperCase();
};
