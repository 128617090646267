import React from 'react';
import * as S from './styles';

function Table({ children, ...restProps }) {
  return <S.Table {...restProps}>{children}</S.Table>;
}

Table.Head = function Head({ children, ...restProps }) {
  return <S.Head {...restProps}>{children}</S.Head>;
};

Table.HeadRow = function HeadRow({ children, ...restProps }) {
  return <S.HeadRow {...restProps}>{children}</S.HeadRow>;
};

Table.Body = function Body({ children, ...restProps }) {
  return <S.Body {...restProps}>{children}</S.Body>;
};

Table.BodyRow = function BodyRow({ children, ...restProps }) {
  return <S.BodyRow {...restProps}>{children}</S.BodyRow>;
};

Table.Th = function Th({ children, ...restProps }) {
  return <S.Th {...restProps}>{children}</S.Th>;
};

Table.Td = function Td({ children, ...restProps }) {
  return <S.Td {...restProps}>{children}</S.Td>;
};

Table.Foot = function Foot({ children, ...restProps }) {
  return <S.Foot {...restProps}>{children}</S.Foot>;
};

export default Table;
