import styled from '@emotion/styled';

export const Image = styled.img(
  {
    display: 'block',
  },
  (props) => ({
    objectFit: props.$fit,
    width: props.$width,
    borderRadius: props.radius,
    height: props.height,
  }),
);

export const something = '';
