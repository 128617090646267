import React from 'react';
import { SvgIcon, Text, Button } from 'components/elements';
import { useMediaQuery } from 'hooks';
import { breakpoints } from 'theme';
import PropTypes from 'prop-types';
import * as S from './styles';

function Error({ iconName, label, mainText, buttonLabel, handleClick }) {
  const isTablet = useMediaQuery(breakpoints.mobileDown);

  return (
    <S.Error label="Error">
      <S.InnerWrapper>
        {!isTablet && <SvgIcon name={iconName} color="primary.200" />}
        <S.TextWrapper>
          <Text as="p" variant="label" colour="primary.300" textAlign="center">
            {label}
          </Text>
          <Text variant="h3" colour="primary.700" textAlign="center">
            {mainText}
          </Text>
        </S.TextWrapper>
        {buttonLabel && <Button label={buttonLabel} handleClick={handleClick} />}
      </S.InnerWrapper>
    </S.Error>
  );
}

Error.defaultProps = {
  iconName: '',
  label: '',
  mainText: '',
  buttonLabel: '',
  handleClick: () => {},
};

Error.propTypes = {
  iconName: PropTypes.string,
  label: PropTypes.string,
  mainText: PropTypes.string,
  buttonLabel: PropTypes.string,
  handleClick: PropTypes.func,
};

export default Error;
