import React from 'react';
import { Box, Button, SvgIcon } from 'components/elements';
import { useMediaQuery } from 'hooks';
import { breakpoints } from 'theme';
import { useAuth, useUser } from 'context';
import Navigation from './navigation/Navigation';
import Avatar from './avatar/Avatar';
import MobileNavigation from './mobileNavigation/MobileNavigation';
import { navigationRoutes } from './navigationRoutes';

import * as S from './styles';

function Header() {
  const { isUserAuthenticated, logout, login } = useAuth();
  const { givenName, surname, isVerified, hasSubmitted } = useUser();
  const isTablet = useMediaQuery(breakpoints.tabletDown);

  const concatenatedName = React.useMemo(() => `${givenName} ${surname}`, [givenName, surname]);

  const logInButtonLabel = isUserAuthenticated ? 'Logout' : 'Sign In / Sign Up';

  if (isTablet)
    return (
      <div style={{ zIndex: 1 }}>
        <S.Header label="Header" data-testid="header">
          <SvgIcon name="logo" />
          <Button
            label={logInButtonLabel}
            handleClick={() => (isUserAuthenticated ? logout() : login())}
          />
          {isUserAuthenticated && (!isVerified || hasSubmitted) ? (
            <Avatar name={concatenatedName} />
          ) : null}
        </S.Header>
        <MobileNavigation links={navigationRoutes} />
      </div>
    );

  return (
    <>
      <S.Header label="Header" data-testid="header">
        <SvgIcon name="logo" />
        <Navigation links={navigationRoutes} />
        <Box paddingRight={8}>
          <Button
            label={logInButtonLabel}
            handleClick={() => (isUserAuthenticated ? logout() : login())}
          />
        </Box>
        {isUserAuthenticated && (!isVerified || hasSubmitted) ? (
          <Avatar name={concatenatedName} />
        ) : null}
      </S.Header>
    </>
  );
}

export default Header;
