import styled from '@emotion/styled';
import { spacing } from 'theme';

export const Navigation = styled.nav({});

export const NavList = styled.ul({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
  gap: `clamp(${spacing[16]}, 3vw, ${spacing[36]})`,
});

export const NavItem = styled.li({});
