import styled from '@emotion/styled';
import { typography, palette, spacing } from 'theme';

export const BulletPointsList = styled.ul({
  listStyle: 'disc',
  paddingInlineStart: spacing[12],
});

export const BulletPoint = styled.li({
  fontFamily: typography.fonts.secondary.regular,
  fontSize: typography.fontSizes.m,
  color: palette.neutral[600],
  marginBottom: spacing[4],

  '&::marker': {
    color: palette.primary[700],
  },
});
