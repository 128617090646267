import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

function Badge({
  className,
  colourScheme,
  size,
  radius,
  variant,
  fontSize,
  font,
  fontWeight,
  letterSpace,
  textTransform,
  leftIcon,
  children,
}) {
  return (
    <S.Badge
      className={className}
      colourScheme={colourScheme}
      size={size}
      variant={variant}
      radius={radius}
      fontSize={fontSize}
      letterSpace={letterSpace}
      font={font}
      fontWeight={fontWeight}
      textTransform={textTransform}
    >
      {leftIcon && leftIcon}
      {children}
    </S.Badge>
  );
}

Badge.defaultProps = {
  className: '',
  colourScheme: 'grey',
  size: 'md',
  variant: 'solid',
  radius: 'base',
  fontSize: 's',
  letterSpace: '0.5px',
  font: 'primary',
  fontWeight: 'medium',
  leftIcon: null,
  textTransform: 'none',
};

Badge.propTypes = {
  className: PropTypes.string,
  colourScheme: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  radius: PropTypes.string,
  fontSize: PropTypes.string,
  letterSpace: PropTypes.string,
  font: PropTypes.string,
  fontWeight: PropTypes.string,
  textTransform: PropTypes.string,
  leftIcon: PropTypes.node,
  children: PropTypes.node.isRequired,
};

export default Badge;
